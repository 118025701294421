import Pusher from 'pusher-js';

export var pusher;

var configurePusher = function () {
    var $scriptPusher = $('#script-pusher');
    var key = $scriptPusher.data('key');
    var auth_endpoint = $scriptPusher.data('auth-endpoint');
    var cluster = $scriptPusher.data('cluster');
    var encrypted = $scriptPusher.data('encrypted');

    // Enable pusher logging for debugging - don't include this in production!
    Pusher.logToConsole = false;

    var pusher = new Pusher(key, {
        authEndpoint: auth_endpoint,
        auth: {
            headers: {
                'X-CSRF-TOKEN': $.cookie('csrf_access_token')
            }
        },
        cluster: cluster,
        encrypted: encrypted
    });

    return pusher;
};

export var pusherLoad = function () {
    pusher = configurePusher();
};

export var pusherSubscribe = function (channelName, eventName, handler) {
    pusherLoad();  // Ensure Pusher is loaded
    var channel = pusher.subscribe(channelName);
    channel.bind(eventName, function (data) {
        handler(data);  // Call the provided handler function with the data
    });
};
