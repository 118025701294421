// Initialize Speech Recognition with Quill
// initializeSpeechRecognition(quill);

import logger from "./logger";
export let speech_recognition;
export let used_speech_recognition = false

export function reset_used_speech_recognition(){
    used_speech_recognition = false
}

export function initializeSpeechRecognition(quill) {
    // Find the mic button by its class name
    const micButton = document.querySelector('.mic-button'); // Assume your button has the class 'mic-button'

    if (!micButton) {
        logger.error("Mic button element not found");
        return;
    }

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
        logger.log("Speech Recognition is not supported in this browser.");
        micButton.classList.add('d-none');
        return;

    }



    // Initialize SpeechRecognition
    speech_recognition = new SpeechRecognition();
    speech_recognition.lang = 'en-US';
    speech_recognition.interimResults = true; // Enable interim results
    speech_recognition.maxAlternatives = 1;
    speech_recognition.continuous = true;

    let isRecognitionActive = false; // Track recognition state
    let startPos = null; // Start position for text insertion
    let lastPos = null;  // Tracks the length of the most recent text insertion

    // Start or stop recognition on button click
    micButton.addEventListener('click', () => {
        if (isRecognitionActive) {
            // Stop recognition if it's currently active
            speech_recognition.stop();
        } else {
            // Start recognition if it's not active
            micButton.classList.add('btn-warning');
            micButton.classList.remove('btn-light');
            speech_recognition.start();
            isRecognitionActive = true; // Set active flag to true
            used_speech_recognition=true; //used for data collection
        }
    });

    // Handle recognition results
    speech_recognition.onresult = (event) => {
        let concatenatedTranscript = '';

        // Concatenate all results in this event
        for (let i = 0; i < event.results.length; i++) {
            const result = event.results[i];
            concatenatedTranscript += result[0].transcript;

            // Add a space after each final result for readability
            if (result.isFinal) {
                concatenatedTranscript += ' ';
            }
        }

        // Initialize startPos if it's the first time inserting
        if (startPos === null) {
            startPos = quill.getLength() === 1 ? 0 : (quill.getSelection()?.index || quill.getLength());
        }

        // If text has been inserted before, delete it
        if (lastPos !== null) {
            quill.deleteText(startPos, lastPos);
        }

        // Insert the new concatenated transcript and update lastPos
        quill.insertText(startPos, concatenatedTranscript);
        lastPos = concatenatedTranscript.length;

        // Optionally, move the cursor to the end of the inserted text
        quill.setSelection(startPos + lastPos);
    };

    // Handle errors
    speech_recognition.onerror = (event) => {
        logger.error('Speech recognition error:', event.error);
        micButton.classList.remove('btn-warning');
        micButton.classList.add('btn-light');
        isRecognitionActive = false; // Reset the active flag
        startPos = null;
        lastPos = null;
    };

    // When recognition ends, reset the button and state
    speech_recognition.onend = () => {
        logger.log('Speech recognition ended');
        micButton.classList.remove('btn-warning');
        micButton.classList.add('btn-light');
        isRecognitionActive = false; // Reset the active flag
        startPos = null;
        lastPos = null;
    };
}
