import {error_response} from './app-basic.js';
import idFromParamsOrMeta, {meta_id} from './modules/idFromParamsOrMeta.js';
import fileUploadToS3, {uploader} from './modules/fileUploadConfig';
import {copywritingLoad, imagesLoad, outputbox, descriptionbox} from './modules/copywriting.js'
import {hideExamples, examplesCarouselLoad, hidecontrolwithdflex, hidecontrol} from "./modules/promptExamples";
import {AICopywriting, AIChatStream, AIAssistant, AITextImageAudio, AIVideo} from "./modules/copywriting-post";
import {reset_loadinterval, addChatItem, chatLoad, loaderdots, addChatHistory} from "./modules/chat";
import {addVideoToTextHistory, videoToTextLoad} from './modules/videoToText'
import {addVideoHistory} from './modules/video'
import {copyButtonLoad} from './modules/copyButton.js';
import {scrollToBottomLoad} from './modules/scrollToBottom.js';
import {loadVideoCaptions} from './modules/video_captions';
import {pusher, pusherLoad} from "./modules/extensions";
import assistantManager from './modules/assistant';

// // Load and associate jQuery plugins to our global jQuery variable.
// require('jsrender')($);
// require('jquery.cookie');
// !!$.cookie;
import './modules/jquery-setup';
import {voiceLoad} from "./modules/voice-chat";

var postWords = function (action = null) {
    var $formWords = $('#formWords');


    $formWords.on('submit', function (event) {
        event.preventDefault();


        postCopy(event, 'ai_words', 'formWords', false, false, true);

    });

    $formWords.on('keydown', function (event) {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            postCopy(event, 'ai_words', 'formWords', false, false, true);
        }
    });
};


var register_user_metric_group = function () {
    var register_user_metric_group = $('#register_user_metric_group');

    register_user_metric_group.on('submit', function (event) {
        event.preventDefault();

        var url = register_user_metric_group.attr('action');
        var redirect = $('#register_user_metric_group-link').data('next');

        var $error = $('#form_error');

        var metrics = $('#metrics').val();
        var metricsArray = metrics.split(',');
        var periods = $('#periods').val();
        var periodsArray = periods.split(',');
        var sharedwith = $('#shared-with').val();
        var sharedwithArray = sharedwith.split(',');

        var data = {
            'platform': $('#platform').val(),
            'platform_user_id': $('#platform-user-id').val(),
            'account_id': $('#account-id').val(),
            'timezone': $('#timezone').val(),
            'name': $('#name').val(),
            'campaign_name': $('#campaign-name').val(),
            'metrics': metricsArray,
            'periods': periodsArray,
            'shared_with': sharedwithArray,
            'status': $('#status').val()
        }

        return $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data),
            processData: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-TOKEN', $.cookie('csrf_access_token'));
            },
            success: function (response, status, xhr) {

                window.location.replace(redirect);

            },
            error: function (response, status, xhr) {
                $error.text(error_response(response));
                $error.show();
            }
        });
    });
};

var register_app = function () {
    var register_app = $('#register_app');

    register_app.on('submit', function (event) {
        event.preventDefault();

        var url = register_app.attr('action');
        var redirect = $('#register_app-link').data('next');
        var shopify = $('#register_app-link').data('shopify');
        var $url = $('#url');
        var $error = $('#form_error');

        var data = {
            'domain': $url.val()
        }

        return $.ajax({
            type: 'post',
            url: url,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data),
            processData: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-TOKEN', $.cookie('csrf_access_token'));
            },
            success: function (response, status, xhr) {

                var $title = $('#title');

                if ($title[0].innerText != 'Setup Complete') {
                    var $btn = $('#register_app-link');
                    $btn[0].innerHTML = "View Live Dashboard"
                    $btn.addClass('bg-gradient-success')

                    $title[0].innerHTML = "Setup Complete"
                    var $subtitle = $('#subtitle');
                    $subtitle[0].innerHTML = "Click View Live Dashboard to see your live data"
                    window.open(shopify, '_blank');
                } else {
                    window.location.replace(redirect);
                }
            },
            error: function (response, status, xhr) {
                $error.text(error_response(response));
                $error.show();
            }
        });
    });
};


var chatinputbox


// Initialize everything when the DOM is ready.
$(document).ready(function () {


        // console.log(window.location.pathname)
        switch (window.location.pathname) {

            case '/setup/':
                register_app();
                break;
            case '/data/metric-groups/setup':
                register_user_metric_group();
                break;

        }


        switch (document.title) {

            // case 'AI Assistant':
            //     AIAssistant();

            //     descriptionbox.focus()
            //     break;
            case 'AI Assistant':
                copywritingLoad('ai_assistant', "Ask me anything", "formAssistant");
                AIAssistant(null, 'formAssistant', true)

                descriptionbox.focus()
                break;

            case 'AI Detector':
                copywritingLoad("ai_assistant", "Enter text for AI Detection", "formAssistant")
                AIAssistant(null, 'formAssistant', true)

                descriptionbox.focus()

                break;

            case 'AI Humaniser':
                copywritingLoad("ai_humaniser", "Enter Text to Humanise", "formAssistant")
                AIAssistant(null, 'formAssistant', true, false, true)

                descriptionbox.focus()

                break;


            case 'AI Search' :
                copywritingLoad('search', "Search for anything", "formAssistant", true);
                AIAssistant(null, 'formAssistant', true)

                descriptionbox.focus();
                break;

            case 'AI Video to Text':
                videoToTextLoad()
                copywritingLoad('search', "Ask me anything", "formAssistant");
                AIAssistant(null, 'formAssistant', false, false)
                pusherLoad();
                fileUploadToS3("video_to_text", "Click to choose a video or drag and drop it here");
                AIChatStream();
                chatLoad(0)

                addVideoToTextHistory(chat_history, 1);

                break;

            case 'AI Video Captions':
                videoToTextLoad()
                loadVideoCaptions();

                pusherLoad();
                fileUploadToS3("video_captions", "Click to choose a video or drag and drop it here");
                AIChatStream();
                chatLoad(0)

                addVideoToTextHistory(chat_history, 1);

                break;


            case 'AI Copywriting':
                copywritingLoad();
                AICopywriting(null, 'formCopywriting', true);
                AIChatStream();


                var $error = $('#form_error');
                $error.hide();

                //chat code for fixed-plugin
                chatLoad(0)
                assistantManager.attach()
                // addChatHistory(chat_history, 0, 'AI Assistant');
                copyButtonLoad();
                scrollToBottomLoad();

                break;

            case 'AI Images':
                imagesLoad();
                pusherLoad();
                AITextImageAudio('formImage');
                addChatHistory(chat_history, 1, 'AI Images');
                copyButtonLoad();

                break;
            case
            'AI Words':
                idFromParamsOrMeta();
                pusherLoad();
                postWords();
                var privateAppChannel = pusher.subscribe('private-userid-' + meta_id + '-video');
                pusherVideoMessage(privateAppChannel, 'video-message')
                descriptionbox.focus();
                break;
            case
            'AI Video':
                copywritingLoad('ai-assistant', "What video would you like?", "formCopywriting");
                pusherLoad();
                AIVideo();
                addVideoHistory(chat_history, 1);

                descriptionbox.focus();
                break;

            case
            'AI Chat 2'
            :
                AIChatStream();

                chatLoad();
                assistantManager.attach()
                copyButtonLoad();
                scrollToBottomLoad();
                addChatHistory(chat_history, 1, 'AI Chat 2');

                break;
            case 'AI Voice Chat':
                voiceLoad()
                assistantManager.attach()
            default:
            // code block
        }


    }
)
;
