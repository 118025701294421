// idFromParamsOrMeta.js
export var meta_id;
export var meta_page_id
export var app_param;

export default function idFromParamsOrMeta() {
    //check query string for id
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    meta_id = params.id; // "some_value"

    //if no query string get id from meta
    if (!meta_id) {
        meta_id = $('meta[name=id]').attr('content');
    }

    meta_page_id = $('meta[name=page_id]').attr('content');
    // Assign `app` from query string
    app_param = params.app;
}


