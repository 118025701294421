import {customLogger} from './logger'

const logger = customLogger('assistant', 'orange')

class AssistantManager {
    constructor(selector) {

        this.selector = selector;
        this.container = document.querySelector(this.selector);

        this.isAttached = false;
        this.assistant_instruction_quill = null;
        this.editingAssistantId = null;
        this.selectedAssistantId = null;

        this.listeners = [];
    }

    addListener(callback) {

        if (typeof callback === 'function') {
            this.listeners.push(callback);
        }
    }

    removeListener(callback) {
        this.listeners = this.listeners.filter(listener => listener !== callback);
    }

    notifyListeners() {

        this.listeners.forEach(callback => callback(this.selectedAssistantId));
    }

    attach() {


        if (this.isAttached) return;
        if (!this.container) return;

        this.attachUI();
        this.attachEvents();
        this.isAttached = true;
    }

    attachUI() {

        this.assistant_instruction_quill = new Quill('#assistant-instruction', {
            theme: 'snow', modules: {
                toolbar: false,
            },
        });

        this.loadAssistantList();
    }

    attachEvents() {

        const assistantForm = document.getElementById('assistant-form');
        if (assistantForm) {
            assistantForm.addEventListener('submit', (e) => {
                e.preventDefault();
                this.saveAssistant();
            });
        }

        const dropdownButton = document.getElementById('selectedAssistantName');
        if (dropdownButton) {
            dropdownButton.addEventListener('click', () => {
                const expanded = dropdownButton.getAttribute('aria-expanded') === 'true';
                dropdownButton.setAttribute('aria-expanded', !expanded);
            });
        }
    }

    loadAssistantList() {
        fetch('/api/v1/assistants', {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                const assistantsList = document.getElementById('assistants-list');
                assistantsList.innerHTML = `
              <li>
                <h6 class="dropdown-header d-flex justify-content-between align-items-center">
                  Available Assistants
                  <button id="create-assistant-btn" class="btn btn-link p-0 m-0 ml-2 ps-2"
                  aria-label="New AI Assistant">
                    <i class="fas fa-plus"></i>
                  </button>
                </h6>
              </li>`;

                const createAssistantBtn = assistantsList.querySelector('#create-assistant-btn');
                createAssistantBtn.onclick = (event) => {
                    event.preventDefault();
                    this.editingAssistantId = null;
                    this.showAssistantEditor();
                };

                data.forEach((assistant) => {
                    const listItem = document.createElement('li');
                    listItem.dataset.id = assistant.id;

                    const listLink = document.createElement('a');
                    listLink.classList.add('dropdown-item', 'text-body', 'rounded-1', 'd-flex', 'justify-content-between', 'align-items-center');
                    listLink.href = `#${assistant.id}`;
                    listLink.onclick = (event) => {
                        event.preventDefault();
                        this.handleAssistantSelection(assistant.id, assistant.name);
                    };

                    const span = document.createElement('span');
                    span.classList.add('pe-2');
                    span.textContent = assistant.name;

                    listLink.appendChild(span);

                    // Only show settings cog if assistant is editable (is_system: false)
                    if (!assistant.is_system) {
                        const cogLink = document.createElement('a');
                        cogLink.href = '#';
                        cogLink.innerHTML = '<i class="fas fa-cog cog-icon"></i>';
                        cogLink.onclick = (event) => {
                            event.stopPropagation();
                            this.handleAssistantSelection(assistant.id, assistant.name);
                            this.editingAssistantId = assistant.id;
                            this.showAssistantEditor(assistant.id, event);
                        };
                        listLink.appendChild(cogLink);
                    }

                    listItem.appendChild(listLink);
                    assistantsList.appendChild(listItem);
                });

                const divider = document.createElement('hr');
                divider.classList.add('dropdown-divider');
                assistantsList.appendChild(divider);

                const footerListItem = document.createElement('li');
                const footerLink = document.createElement('a');
                footerLink.classList.add('dropdown-item', 'text-body', 'text-xs');
                footerLink.href = '/how-to/custom-assistant';
                footerLink.textContent = 'Help';
                footerLink.style.textDecoration = 'underline';

                footerListItem.appendChild(footerLink);
                assistantsList.appendChild(footerListItem);
            });
    }

    handleAssistantSelection(assistantId, assistantName) {

        if (this.editingAssistantId && this.editingAssistantId !== assistantId) {
            this.closeAssistantEditor();
        }
        this.selectAssistant(assistantId, assistantName);
    }

    selectAssistant(assistantId, assistantName) {


        this.selectedAssistantId = assistantId;
        // Notify all listeners that the assistant was updated
        this.notifyListeners();
        const dropdownButton = document.getElementById('selectedAssistantName');
        dropdownButton.innerText = `${assistantName} `;
        dropdownButton.setAttribute('aria-expanded', false);  // Close dropdown after selection
    }

    getSelectedAssistantId() {
        return this.selectedAssistantId;
    }

    showAssistantEditor(assistantId = null, event = null) {


        if (event) {
            event.stopPropagation();
        }
        const popup = document.getElementById('form-popup');
        popup.style.display = 'block';
        popup.setAttribute('aria-hidden', 'false');  // Popup is visible now
        popup.focus();

        if (assistantId) {
            fetch(`/api/v1/assistants/${assistantId}`, {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((data) => {
                    document.getElementById('assistant-name').value = data.name;
                    this.assistant_instruction_quill.setText(data.instructions, 'api');
                });
        } else {
            document.getElementById('assistant-name').value = '';
            this.assistant_instruction_quill.setText('');
        }
    }

    closeAssistantEditor() {

        const popup = document.getElementById('form-popup');
        popup.style.display = 'none';
        popup.setAttribute('aria-hidden', 'true');  // Popup is hidden
        this.editingAssistantId = null;
    }

    saveAssistant() {

        const saveButton = document.querySelector('#assistant-form button[type="submit"]');
        saveButton.disabled = true;
        saveButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...';

        const name = document.getElementById('assistant-name').value;
        const instruction = {text: this.assistant_instruction_quill.getText()};
        const assistantData = {
            name: name, instructions: instruction,
        };

        let url = '/api/v1/assistants';
        if (this.editingAssistantId) {
            url = `/api/v1/assistants/${this.editingAssistantId}`;
        }

        fetch(url, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.cookie.split('; ').find(row => row.startsWith('csrf_access_token')).split('=')[1],
            }, body: JSON.stringify(assistantData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to save the assistant');
                }
                return response.json();
            })
            .then((data) => {
                saveButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                setTimeout(() => {
                    saveButton.innerHTML = 'Save';
                    saveButton.disabled = false;
                }, 1500);
                this.editingAssistantId = data.id;
                this.loadAssistantList();
                this.selectAssistant(data.id, data.name);
            })
            .catch((error) => {
                logger.log('saveAssistant error:', error);
                saveButton.innerHTML = 'Failed';
                saveButton.classList.add('btn-danger');
                saveButton.disabled = false;
                setTimeout(() => {
                    saveButton.innerHTML = 'Save';
                    saveButton.classList.remove('btn-danger');
                }, 1500);
            });
    }
}

const assistantManager = new AssistantManager('#assistant-container');
export default assistantManager;
