export var scrollToBottomLoad = function () {
    const chatContainer = document.getElementById('chat-container');
    const scrollToBottomBtn = document.getElementById('scroll-to-bottom');
    const chatInputBox = document.getElementById('chatinputbox');

    function adjustScrollButtonPosition() {
        const chatInputBoxHeight = chatInputBox.offsetHeight;
        scrollToBottomBtn.style.bottom = (chatInputBoxHeight + 30) + 'px'; // Add some extra space if needed
    }

    chatContainer.addEventListener('scroll', () => {
        if (chatContainer.scrollTop + chatContainer.clientHeight >= chatContainer.scrollHeight - 10) {
            scrollToBottomBtn.style.display = 'none';
        } else {
            scrollToBottomBtn.style.display = 'block';
        }
    });

    scrollToBottomBtn.addEventListener('click', () => {
        chatContainer.scrollTop = chatContainer.scrollHeight;
    });

    // Scroll to bottom on initial load
    chatContainer.scrollTop = chatContainer.scrollHeight;

    // Adjust position on load
    adjustScrollButtonPosition();

    // Adjust position whenever the input box size changes
    new ResizeObserver(adjustScrollButtonPosition).observe(chatInputBox);
}
