import {hideexamplescarousel} from "./promptExamples";
export function addVideoHistory(chat_history, version = 1) {
    if (chat_history != null) {

        $('#popupVideo').modal('show')

        var $video = $('#videoresult');
        $video[0].src = chat_history[1].content[0].file.url
        $video[0].load();

        var $progressdiv = $('#progressdiv');
        $progressdiv.hide()

        var $updatemessage = $('#update-message');
        $updatemessage.hide();

        hideexamplescarousel('carouselExamples_desktop')
        hideexamplescarousel('carouselExamples_mobile')


    }
}