import {
    addToggleEventHandler,
    addCarouselToggleEventHandler,
    addKeywordsToggleEventHandler,
    addGroupsToggleEventHandler,
    addTagsToggleEventHandler
} from './eventHanders.js'
import {fromCookieValueKeywords, fromCookieValueCarouselExamples} from "./cookies.js";
import {addDropdownToMenu} from "./quillWilfieMenu";
import {examplesCarouselLoad} from "./promptExamples";
import throttle from './throttle.js';
import logger from './logger'


export var outputbox
export var descriptionbox
export var instructionoptions
export var keywordTags
export var groupTags
export var customTags
export var selectiontext = ''
export var isFavorite = (typeof doc !== 'undefined' && doc?.is_favorite) || false;

// Store accumulated changes
var Delta = Quill.import('delta');
var change = new Delta();
var unsavedchanges = false;
var savechangesinprogress = false;
var saveretries = 0;
var textnewdoc = ''
var textnewdocrange = 0
var tnd = 0
var loaded = false


export var copywritingLoad = function (page_type = 'copywriting', input_placeholder_text = "Enter a description", form_name = "formCopywriting", read_only=false) {


    function typeNewDoc() {
        if (tnd < textnewdoc.length) {
            outputbox.insertText(textnewdocrange + tnd, textnewdoc.charAt(tnd), {'header': false})
            outputbox.setSelection(0, 0);

            tnd++;
            setTimeout(typeNewDoc, 10);
        }

    }

    Quill.register('modules/counter', function (box, options) {
        var container = document.querySelector(options.container);
        var hightlightcontainer = document.querySelector(options.highlightcontainer);


        box.on('text-change', function (delta) {
            unsavedchanges = true;

            change = change.compose(delta);

            hightlightcontainer.innerText = ''
            var text = box.getText().trim();

            if (text.length > 0) {
                var wordcount = (text.split(/\s+/).length)
                var wordcounttext = 'words'
                if (wordcount == 1) {
                    wordcounttext = 'word'
                }

                var characters = text.length

                if (options.limit !== null) {

                    var remainingcount = options.limit - characters
                    remainingcount = Math.abs(remainingcount)
                    var remainingtext = 'characters'
                    if (remainingcount === 1) {
                        remainingtext = 'character'
                    }
                    if (characters < options.limit / 2) {
                        container.innerText = '';
                        container.className = 'text-xs'
                    } else if (characters > options.limit) {
                        container.innerText = 'Max ' + options.limit + ' characters, please delete ' + remainingcount + ' ' + remainingtext;
                        container.className = 'text-xs text-danger mb-n2'
                    } else {
                        container.innerText = characters + '/' + options.limit + ' ' + remainingtext;
                        container.className = 'text-xs mb-n2'
                    }
                } else {
                    container.innerText = wordcount + ' ' + 'words';
                    container.className = 'text-xs mb-n2'
                }

            } else {
                container.innerText = ''
                container.className = 'text-xs mb-n2'
            }

            selectiontext = ''

        });
        box.on('selection-change', function (range, oldRange, source) {
            var btnoptions = document.querySelector('.ql-btncreate');
            if (range !== null && range.length > 0) {

                if (btnoptions) {
                    btnoptions.children[0].dataset.label = 'Instructions'
                }

                selectiontext = box.getText(range.index, range.length).trim();
                var wordcount = (selectiontext.split(/\s+/).length)
                var wordcounttext = 'words'
                if (wordcount == 1) {
                    wordcounttext = 'word'
                }
                var charactercounttext = 'characters'
                var characterscount = selectiontext.length
                if (characterscount == 1) {
                    charactercounttext = 'character'
                }

                hightlightcontainer.innerText = '(highlighted ' + wordcount + ' ' + wordcounttext + ' ' + characterscount + ' ' + charactercounttext + ')';
                hightlightcontainer.className = 'text-xs mb-n2'
            } else {
                hightlightcontainer.innerText = ''
                selectiontext = ''
                if (btnoptions) {
                    btnoptions.children[0].dataset.label = 'Highlight Text...'
                }
            }


        });
    });
    descriptionbox = new Quill('#description', {
        placeholder: input_placeholder_text,
        theme: 'snow', // Specify theme in configuration


        modules: {
            toolbar: false,
            counter: {
                container: '#counter',
                highlightcontainer: '#counterhighlight',
                limit: 8000
            }
        },
        formats: []
    });

    var toolbarOptions = [
        [{'header': 1}, {'header': 2}],               // custom button values
        ['bold', 'italic', 'underline'],        // toggled buttons=
        [{'align': []}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'color': []}],
        [{'background': []}],
        // dropdown with defaults from theme
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'indent': '-1'}, {'indent': '+1'}],
        ['blockquote', 'code-block'],

        ['clean']                                         // remove formatting button
    ];

    var output_placeholder_text = ""
    if (page_type == "copywriting") {
        output_placeholder_text = "AI Document, type a command and highlight for options "
    }

    outputbox = new Quill('#editor', {

        placeholder: output_placeholder_text,

        bounds:
            "#editor",
        theme:
            'bubble', // Specify theme in configuration
            readOnly: read_only,
        modules:
            {
                toolbar: toolbarOptions,
                counter:
                    {
                        container: '#counteroutput',
                        highlightcontainer:
                            '#counterhighlightoutput',
                        unit:
                            'word',
                        limit:
                            null
                    }
            }

    })
    ;

    if (page_type == "copywriting") {
        const quillLeftMenu = document.getElementById("quill-left-menu");
        const aiCopy = document.getElementById("aicopy")

        const throttledUpdateMenuPosition = throttle(updateMenuPosition, 50);
        outputbox.container.addEventListener('mousemove', throttledUpdateMenuPosition);


        let menuRange = null;

        function updateMenuPosition(event) {
            const x = event.clientX;
            const y = event.clientY;
            let pos;

            // Check for browser support for caret position methods
            if (typeof document.caretPositionFromPoint === 'function') {
                pos = document.caretPositionFromPoint(x, y);
            } else if (typeof document.caretRangeFromPoint === 'function') {
                pos = document.caretRangeFromPoint(x, y);
            } else {
                logger.warn('Browser does not support caret position methods');
                return; // Exit the function if neither method is available
            }

            if (pos) {
                const node = pos.offsetNode || pos.startContainer;
                const offset = pos.offset !== undefined ? pos.offset : pos.startOffset !== undefined ? pos.startOffset : 0;

                const blot = Quill.find(node, true);

                if (blot && typeof blot.offset === 'function') {
                    const blotIndex = blot.offset(outputbox.scroll);


                    // Proceed only if offset is defined
                    if (offset !== undefined && blotIndex !== undefined) {
                        const index = blotIndex + offset;

                        // Check if the index is a valid number
                        if (!isNaN(index)) {
                            const range = {index: index, length: 0};
                            updateLeftMenu(range);
                        } else {

                        }
                    }
                }
            }

        }


        function updateLeftMenu(range) {
            if (range) {
                menuRange = range; // Store the range
                const start = range.index;
                const [block, offset] = outputbox.getLines(start);
                if (block.length() > 1) {
                    quillLeftMenu.style.visibility = 'visible';
                    const bounds = outputbox.getBounds(start);
                    var top_position = (bounds.top - 9) + "px";
                    var left_position = 0;
                    quillLeftMenu.style.transform = `translate(${left_position}, ${top_position})`;
                } else {
                    quillLeftMenu.style.visibility = 'hidden';
                }
            }
        }


        outputbox.on('selection-change', function (range) {
            logger.log('selection-change')
            updateLeftMenu(range)


        });

        outputbox.on('text-change', function () {
            logger.log('text-change')
            const range = outputbox.getSelection(true);
            updateLeftMenu(range);

        });

        quillLeftMenu.addEventListener("click", function () {
            outputbox.setSelection(menuRange.index, 0, 'user');
            // console.log('menuRange.index: ' + menuRange.index)
            var start = outputbox.getSelection(true).index;
            // console.log('start ' + start)
            // const [blockline] = outputbox.getLine(start);
            // console.log('blockline.length(): ' + blockline.length())
            // console.log('blockline.offset(): ' + blockline.offset())


            const [block, offset] = outputbox.getLines(start);
            // console.log('block.length(): ' + block.length())
            // console.log('block.offset(): ' + block.offset())

            if (block) {
                const blockIndex = outputbox.getIndex(block);
                const blockLength = block.length();
                // console.log('blockLength: ' + blockLength)
                // console.log('blockIndex: ' + blockIndex)
                // Select the entire block (line)
                outputbox.setSelection(blockIndex, block.length(), 'user');
                length = block.length();  // Update length to the block length
                start = block.offset();   // Update start to the block offset
                // Show and position the tooltip
                const bounds = outputbox.getBounds(blockIndex, length);
                outputbox.theme.tooltip.show();
                outputbox.theme.tooltip.position(bounds);
            }


        });
        // quillLeftMenu.addEventListener("click", function () {
        //     outputbox.setSelection(menuRange.index, 0, 'user');
        //     var start = outputbox.getSelection(true).index;
        //     const [block, offset] = outputbox.getLines(start);
        //
        //     if (block) {
        //         // Select the entire block (line)
        //         outputbox.setSelection(block.offset(), block.length(), 'user');
        //         length = block.length();  // Update length to the block length
        //         start = block.offset();   // Update start to the block offset
        //     }
        //     // Show and position the tooltip
        //     const bounds = outputbox.getBounds(start, length);
        //     outputbox.theme.tooltip.show();
        //     outputbox.theme.tooltip.position(bounds);
        // });
    }

    outputbox.getModule("toolbar").container.addEventListener("mousedown", (e) => {
        e.preventDefault();
    });


    instructionoptions = document.getElementById('instruction-options');
    const instructions = new Choices(instructionoptions,
        {
            searchPlaceholderValue: 'Search Templates...',
            shouldSort: false,
            position: 'bottom', // Set default position
        });

    instructionoptions.addEventListener(
        'change',
        function (event) {
            // do something creative here...
            unsavedchanges = true;
            // console.log('instruction changed', change);
        },
        false,
    );

    // Keywords - Optional Control
    keywordTags = document.getElementById('keyword-tags');
    var color = keywordTags.dataset.color;
    const kTags = new Choices(keywordTags, {
        placeholder: true,
        placeholderValue: 'Enter your keywords',
        delimiter: ',',
        editItems: true,
        maxItemCount: 10,
        removeItemButton: true,
        addItems: true,
        classNames: {
            item: 'badge rounded-pill choices-' + color + ' me-2'
        }
    });
    keywordTags.addEventListener(
        'change',
        function (event) {
            // do something creative here...
            unsavedchanges = true;
            // console.log('tag changed', change);
        },
        false,
    );

    customTags = document.getElementById('custom-tags');
    if (customTags) {
        var color = customTags.dataset.color;
        var cTags = new Choices(customTags, {
            placeholder: false,
            delimiter: ',',
            editItems: true,
            maxItemCount: 10,
            removeItemButton: true,
            addItems: true,
            classNames: {
                item: 'badge rounded-pill choices-' + color + ' me-2'
            }
        });
        // Initial check for customTagsContainer
        const customTagsContainer = document.getElementById('custom-tags-container-div');

        if (page_type == "copywriting") {
            if (customTagsContainer) {
                customTagsContainer.addEventListener('click', function (event) {
                    // Focus on the custom-tags input field
                    cTags.input.element.focus();
                });
                customTagsContainer.addEventListener('click', function (event) {
                    // Custom Tag Icon is Clicked
                    customTags.focus();
                });

                function toggleCustomTagsContainer() {
                    if (customTags.value || document.activeElement === customTags) {
                        customTagsContainer.style.display = 'none';
                    } else {
                        customTagsContainer.style.display = 'block';
                    }
                }

                customTags.addEventListener('change', function (event) {
                    toggleCustomTagsContainer();
                    unsavedchanges = true;
                }, false);

                customTags.addEventListener('focus', function (event) {
                    toggleCustomTagsContainer();
                }, false);

                customTags.addEventListener('blur', function (event) {
                    toggleCustomTagsContainer();
                }, false);

                // Initial check
                toggleCustomTagsContainer();
            }
        }
    }

    var errorbar = document.getElementById('form_error');
    document.getElementById("aicopy").prepend(errorbar);

    var qlcontainer = document.getElementById("description");
    qlcontainer.style.setProperty("border-top-left-radius", '0.5rem');
    qlcontainer.style.setProperty("border-top-right-radius", '0.5rem');

    var qlEditor = qlcontainer.querySelector('.ql-editor');
    qlEditor.style.setProperty("max-height", '300px');

    var qlresult = document.getElementsByClassName("ql-editor");

    if (page_type == "copywriting") {
        qlresult[1].style.setProperty("min-height", '80vh');
        qlresult[1].style.setProperty("padding-left", '40px');
    } else {
        qlresult[1].style.setProperty("min-height", '300px');
    }

    // var editor_border = document.getElementById("editor");
    // editor_border.classList.add('border')

    examplesCarouselLoad(false)
    logger.log('page_type == ' + page_type)
    if (page_type == "copywriting") {
        fromCookieValueKeywords()
        addKeywordsToggleEventHandler('keywordsdiv-toggle');


    } else {
        addCarouselToggleEventHandler('carouselExamplesToggle');
        fromCookieValueCarouselExamples()
    }


    if (doc.name != "" && doc.name !== undefined) {
        if (typeof filename !== 'undefined' && filename !== null) {
            filename.value = doc.name
        }
    }
    if (doc.instruction != "" && doc.instruction !== undefined) {
        instructions.setChoiceByValue(doc.instruction);
    }

    if (doc.content != "" && doc.content !== undefined) {
        outputbox.setContents(doc.content['ops'], 'api');
        if ("type_new_text" in doc) {
            var editor_border = document.getElementById("editor");
            editor_border.classList.add('border')
            editor_border.classList.add('border-success')
            textnewdoc = doc.type_new_text
            textnewdocrange = outputbox.getLength()
            typeNewDoc()
        }
    }
    if (doc.description != "" && doc.description !== undefined) {
        descriptionbox.setContents(doc.description['ops'], 'api');
    }
    if (doc.keywords != "" && doc.keywords !== undefined) {
        kTags.setValue(doc.keywords);
    }
    if (doc.group != "" && doc.group !== undefined) {
        groupsTagsContainer.style.display = 'none';
        gTags.setValue(doc.group);
    }
    // Set value and toggle visibility for custom tags
    if (customTags && cTags && doc.tags != "" && doc.tags !== undefined) {
        const customTagsContainer = document.getElementById('custom-tags-container-div');
        if (customTagsContainer) {
            customTagsContainer.style.display = 'none';
        }
        cTags.setValue(doc.tags);
    }


    loaded = true
    unsavedchanges = false

    if (page_type == "copywriting") {
        saveChangesToAPI()
        manageFileName()
        manageFavoriteStatus();
    }

    addDropdownToMenu(outputbox, null, form_name, true);

    const clearbutton = document.getElementById("clearDescription")

    if (clearbutton) {
        clearbutton.addEventListener('click', () => {

            descriptionbox.setContents([], 'api');
            document.getElementById("description").classList.remove("border-primary");
            descriptionbox.focus()
        });
    }

    if (page_type === "ai_humaniser") {

    }

}


var saveChangesToAPI = function () {
    // Check for unsaved data
    window.onbeforeunload = function () {
        // if (change.length() > 0) {
        if (unsavedchanges) {
            return 'There are unsaved changes. Are you sure you want to leave?';
        }
    }

    // Save periodically
    setInterval(function () {
        // if (change.length() > 0) {
        if (unsavedchanges && loaded) {
            if (savechangesinprogress === false) {
                // console.log('Saving changes');
                savechangesinprogress = true
                /*
                Send partial changes
                $.post('/your-endpoint', {
                  partial: JSON.stringify(change)
                });

                Send entire document*/
                var data = {
                    'doc_code': $('meta[name=doc_code]').attr('content'),
                    'version': 1.0,
                    'name': filename.value,
                    'instruction': instructionoptions.value,
                    'description': descriptionbox.getContents(),
                    'keywords': keywordTags.value.split(","),
                    'content': outputbox.getContents(),
                    'group': groupTags ? groupTags.value.split(",") : [],
                    'tags': customTags ? customTags.value.split(",") : [],
                    'is_favorite': isFavorite,
                    'is_deleted': false
                }
                return $.ajax({
                    type: 'post',
                    url: '/api/v1/copywriting/save/',
                    dataType: 'json',
                    contentType: 'application/json',
                    data: JSON.stringify(data),
                    processData: false,
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('X-CSRF-TOKEN', $.cookie('csrf_access_token'));
                    },
                    success: function (response, status, xhr) {
                        change = new Delta();
                        unsavedchanges = false;
                        savechangesinprogress = false
                    },
                    error: function (response, status, xhr) {
                        change = new Delta();
                        savechangesinprogress = false
                    }
                });

            } else {
                // console.log('Saving skipped');
            }
        }
    }, 4 * 1000);

}

var manageFileName = function () {
    var filename = document.getElementById('filename');
    filename.addEventListener('input', function () {
        unsavedchanges = true;

        // console.log('filename changed', change);
    });
    filename.addEventListener('keypress', function () {
        var keyPressed = event.keyCode || event.which;
        if (keyPressed === 13) {

            event.preventDefault();
            filename.blur();
            return false;


        }
    });
    filename.addEventListener('blur', function () {
        if (filename.value.length < 1) {
            filename.value = 'AI Copywriting'
        }
    });
    filename.addEventListener('mouseover', function () {
        filename.classList.toggle('border-0')

    });
    filename.addEventListener('mouseleave', function () {
        filename.classList.toggle('border-0')
    });
}

var manageFavoriteStatus = function () {
    var favoriteButton = document.getElementById('is_favorite');
    // Set initial state based on is_favorite
    if (doc.is_favorite) {
        favoriteButton.classList.add('star-filled');
        favoriteButton.classList.remove('star-outline');
    } else {
        favoriteButton.classList.add('star-outline');
        favoriteButton.classList.remove('star-filled');
    }
    favoriteButton.addEventListener('click', function () {
        isFavorite = !isFavorite;
        unsavedchanges = true;
        this.classList.toggle('star-outline');
        this.classList.toggle('star-filled');
    });
}

export var imagesLoad = function (height_padding = 60) {

    var selectiontext = ''

    descriptionbox = new Quill('#description', {
        placeholder: "What image would you like?",
        theme: 'snow', // Specify theme in configuration

        modules: {
            toolbar: false,

        },
        formats: []
    });


    var qleditor = document.getElementById("description");
    qleditor.style.setProperty("border-top-left-radius", '0.5rem');
    qleditor.style.setProperty("border-top-right-radius", '0.5rem');
    qleditor.style.setProperty("max-height", '300px');
    qleditor.style.setProperty("padding-right", '100px');

    var chat_container = document.getElementById("chat_block");
    chat_container.style.setProperty('max-height', ((window.innerHeight - 50) + "px"));


    fromCookieValueCarouselExamples()
    addCarouselToggleEventHandler('carouselExamplesToggle');

    examplesCarouselLoad(false)

    let icons = [
        {class: 'far fa-square fa-2x', value: '1024x1024', title: 'Square (1024x1024)'},
        {class: 'fas fa-portrait fa-2x', value: '1024x1792', title: 'Portrait (1024x1792)'},
        {class: 'far fa-image fa-2x', value: '1792x1024', title: 'Landscape (1792x1024)'}
    ];
    let text = [
        'Try Portrait and landscape Images',
        'Portrait Mode',
        'Landscape Mode'
    ]
    let index = 0;
    let currentIcon = icons[index].value;

    $('#chat-toggle').find('i').addClass(icons[index].class).data('icon', currentIcon); // Set default icon
    $('#chat-toggle2').find('span').text('Try Portrait and landscape Images').append(' <i class="fa fa-arrow-right"></i>');

    $('#chat-toggle').on('click', function () {
        index = (index + 1) % icons.length; // Cycle index
        currentIcon = icons[index].value;
        $(this).find('i').removeClass().addClass(icons[index].class).data('icon', currentIcon); // Change icon
        $('#chat-toggle2').find('span').text(text[index]).append(' <i class="fa fa-arrow-right"></i>');
    });

}