import {fromCookieValueCarouselExamples, update_cookie} from "./cookies";
import {chatinputbox} from "./chat";
import {descriptionbox} from "./copywriting";

export var hideExamples = function (ele) {
    var cont = document.getElementById(ele);
    var icon = document.getElementById(ele + '-icon');

    if (cont.className == 'carousel slide d-none d-sm-block') {
        cont.className = 'carousel slide d-none d-sm-none';
        icon.className = 'fas fa-angle-down ml-1 align-text-bottom'
    }
}

export var examplesCarouselLoad = function (chat = true) {


    var examplescarousel = document.getElementById('carouselExamples_desktop')

    if (examplescarousel) {
        fromCookieValueCarouselExamples();
        const cards = document.querySelectorAll('.examplecard');
        cards.forEach(card => {
            card.addEventListener('click', () => {
                if (chat) {
                    document.getElementById("chatinputbox").classList.add("border-primary");
                    chatinputbox.setContents([{insert: card.children[0].children[1].textContent.trim().slice(1, -1)}], 'api');

                    chatinputbox.focus()
                } else {
                    document.getElementById("description").classList.add("border-primary");
                    descriptionbox.setContents([{insert: card.children[0].children[1].textContent.trim().slice(1, -1)}], 'api');
                    descriptionbox.focus()
                }


            });
        });
    }

}


export var toggleExampleCarousel = function () {

    var cont = document.getElementById('carouselExamples_desktop');
    var icon = document.getElementById('carouselExamples_desktop-icon');

    if (cont.className == 'carousel slide d-none d-sm-block') {
        cont.className = 'carousel slide d-none d-sm-none';
        icon.className = 'fas fa-angle-down ml-1 align-text-bottom';
        update_cookie('carousel-examples-state', "hide");
    } else {
        cont.className = 'carousel slide d-none d-sm-block';
        icon.className = 'fas fa-angle-up ml-1 align-text-bottom';
        update_cookie('carousel-examples-state', "show");
    }

}


export var hideexamplescarousel = function (ele) {
    var cont = document.getElementById(ele);
    var icon = document.getElementById(ele + '-icon');

    if (cont) {
        if (cont.className == 'carousel slide d-none d-sm-block') {
            cont.className = 'carousel slide d-none d-sm-none';
            icon.className = 'fas fa-angle-down ml-1 align-text-bottom'
        }
    }
}


export var hidecontrol = function (ele) {
    var cont = document.getElementById(ele);
    cont.style.setProperty("display", 'none');
}

export var hidecontrolwithdflex = function (ele) {
    var cont = document.getElementById(ele);
    cont.classList.remove('d-flex');
    cont.classList.add('d-none');
}
