import { fromCookieValueCarouselExamples } from "./cookies";
import { addCarouselToggleEventHandler } from "./eventHanders";
import { uploadImageToS3 } from './fileUploadConfig';
import { customLogger } from './logger.js';
import { examplesCarouselLoad, hidecontrolwithdflex, hideexamplescarousel } from "./promptExamples";
import { initializeSpeechRecognition } from './speech-recognition';

const logger = customLogger('chat', 'orange');

export let loadInterval

export function loaderdots(element, pretext) {
    element.text(pretext);

    loadInterval = setInterval(() => {
        // Update the text content of the loading indicator
        element.text(element.text() + '.');

        // If the loading indicator has reached three dots, reset it
        if (element.text() === pretext + '....') {
            element.text(pretext);
        }
    }, 400);
}

export function reset_loadinterval() {
    clearInterval(loadInterval);
}

export function addChatItem(content = '') {
    // Check if there is an attached image
    if (chatinputbox) {
        // Extract Semantic HTML content from the Quill editor
        content = chatinputbox.getSemanticHTML();
    }

    var $examples_carousels = $('#examples_carousels');
    if ($examples_carousels && $examples_carousels.length > 0) {
        $examples_carousels[0].className = "d-none";
    }
    var $chatContainer = $('#chat-container');
    var $chatitemtpl = $('#chat-item-tpl');

    // Render the User Message
    var userData = {
        'id': 'user',
        'html': content.trim(), // Use 'html' to support storing of HTML content
        'image': '/images/user.svg',
        'background': '#2152ff'
    };
    var userCardOutput = $chatitemtpl.render({'data': userData});
    $chatContainer.append(userCardOutput);
    // Find the latest target span elements
    const userSpans = $chatContainer[0].querySelectorAll('span#user, span[itemrole="user"]');
    const userSpan = userSpans[userSpans.length - 1]; // Get the last added span
    if (userSpan) {
        // Set the extracted content as the inner HTML of the target span
        userSpan.innerHTML = content.trim();
    }

    // Render the Assistant Message
    var assistantData = {
        'id': 'assistant',
        'input': '',
        'image': '/images/assistant.png',
        'background': '#FFFFFF'
    };
    var assistantCardOutput = $chatitemtpl.render({'data': assistantData});
    $chatContainer.append(assistantCardOutput);

    // Scroll to the bottom of the chat container
    $chatContainer.scrollTop($chatContainer.prop('scrollHeight'));
}

export var chatinputbox

export function reset_chatinputbox() {
    chatinputbox.setContents([])
}

const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'windows';
    } else if (/android/i.test(userAgent)) {
        return 'android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
    } else {
        return 'unknown';
    }
};

// Variables to store the content of Quill and Job IDs
export let savedEditorContent = null;
export let savedJobIds = [];

// Function to set the saved content
export function setSavedEditorContent(content) {
    savedEditorContent = content;
}

// Function to get the saved content
export function getSavedEditorContent() {
    logger.log("GETTING SAVED EDITOR CONTENT", savedEditorContent);
    return savedEditorContent;
}

// Function to capture and save the content from Quill
export function saveEditorContent(quillInstance) {
    const quillContent = quillInstance.getContents(); // Get the full Quill content (including images)
    logger.log("QUILL CONTENT", quillContent);
    setSavedEditorContent(quillContent);
}

// Function to restore the content to Quill
export function restoreEditorContent(quillInstance) {
    const savedContent = getSavedEditorContent();
    logger.log("Restore Editor Content", savedContent);
    if (savedContent) {
        quillInstance.setContents(savedContent); // Restore the saved Quill content
        logger.log("Restored Saved Content", savedContent);
    }
}

export function clearJobIds() {
    logger.log("CLEARING JOB IDS", savedJobIds);
    savedJobIds = [];
}


// Function to remove a specific Job ID
export function removeSavedJobId(index) {
    logger.log("SAVED JOB IDS - BEFORE SPLICE", savedJobIds);
    savedJobIds.splice(index, 1);
    logger.log("SAVED JOB IDS - AFTER SPLICE", savedJobIds);
}

// Insert Job ID
export function addJobId(job_id) {
    logger.log("SAVING JOB ID", job_id);
    savedJobIds.push(job_id)
}

// Function to handle loading Quill content and initializing Quill
export var chatLoad = function (height_padding = 60) {
    var qlchatinputbox = document.getElementById("chatinputbox");
    var pendingUploads = 0; // Counter for pending image uploads
    var $error = $('#chat-form_error');

    if (qlchatinputbox) {

        qlchatinputbox.style.setProperty("border-top-left-radius", '0.5rem');
        qlchatinputbox.style.setProperty("border-top-right-radius", '0.5rem');
        qlchatinputbox.style.setProperty("max-height", '300px');
        qlchatinputbox.style.setProperty("padding-right", '115px');
        qlchatinputbox.style.setProperty("margin-right", '0.5rem');

        chatinputbox = new Quill('#chatinputbox', {
            placeholder: "Ask me anything",
            theme: 'snow',
            modules: {
                toolbar: false,
                clipboard: {
                    matchVisual: false,
                    matchers: [
                        ['IMG', (node, delta) => {
                            const imageDelta = new Quill.imports.delta();
                            imageDelta.retain(delta.ops.length);
                            imageDelta.insert({image: node.getAttribute('src')});
                            return imageDelta;
                        }],
                        ['PRE', (node, delta) => {
                            const codeDelta = new Quill.imports.delta();
                            codeDelta.insert(node.innerText, {'code-block': true});
                            return codeDelta;
                        }],
                        ['CODE', (node, delta) => {
                            const codeDelta = new Quill.imports.delta();
                            codeDelta.insert(node.innerText, {'code': true});
                            return codeDelta;
                        }],
                        [Node.TEXT_NODE, (node, delta) => {
                            // Keep the original formatting, but match the Quill editor's style
                            return new Quill.imports.delta().insert(node.data);
                        }]
                    ]
                }
            },
            formats: [
                'image', 'bold', 'italic', 'header', 'list', 'link', 'underline', 'strike', 'blockquote', 'code-block', 'code'
            ]
        });

        chatinputbox.root.addEventListener('paste', (e) => {
            const clipboardData = e.clipboardData || window.clipboardData;
            const html = clipboardData.getData('text/html'); // Get HTML from the clipboard
            const plainText = clipboardData.getData('text/plain'); // Get plain text from the clipboard
        
            // Let Quill handle the paste, preserving original formatting
            // but with matched Quill styles (using the matcher)
            
            // Prevent default browser behavior, Quill will take care of the paste
            e.preventDefault();
        
            // Handle any specific cases if needed (e.g., pasted images, etc.)
            const items = clipboardData.items;

            // Save only the items that are images
            const images = Array.from(items).filter(item => item.type.indexOf("image") !== -1);

            pendingUploads += images.length;
            logger.log("PENDING COPY PASTE UPLOADS", pendingUploads);

            // Process each pasted image separately and upload to S3
            for (let i = 0; i < images.length; i++) {
                const $submitbutton = $('#chat-submit');
                const $attachmentButton = $('#attachmentButton');
                $submitbutton.addClass('disabled');
                $attachmentButton.addClass('disabled');

                // Get the image as a Blob
                const blob = images[i].getAsFile();
                if (!blob) {
                    logger.error('Failed to process pasted image:', 'Invalid image blob');
                    showErrorMessage('Failed to process pasted image');
                    return;
                }
                
                // Create a FileReader to read the image as a base64 string
                const reader = new FileReader();
                reader.onload = (event) => {
                    // You now have the base64 image as a result
                    const base64ImageSrc = event.target.result;
    
                    // Convert the base64 to a file object and upload it to S3
                    fetch(base64ImageSrc)
                        .then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], "pasted-image.png", {type: blob.type});
                            
                            // Use the existing image upload logic
                            uploadImageToS3(file, (error, signedPost) => {
                                if (error) {
                                    logger.error('Failed to upload image:', error);
                                    showErrorMessage('Failed to upload image');
                                    return;
                                }
                                
                                // Get the uploaded image URL from S3
                                const s3Url = `${signedPost.url}/${signedPost.fields.key}`;
                                logger.log("S3 URL - Successfully Uploaded to S3", s3Url);
                                addJobId(signedPost.job_id);
                                pendingUploads--; // Decrement counter after successful upload
                                checkPendingUploads(pendingUploads);        
                            });
                        })
                        .catch(error => {
                            logger.error('Failed to process pasted image:', error);
                            showErrorMessage('Failed to process pasted image');
                        });
                };
                reader.readAsDataURL(blob);
            }
        });

        // Initialize Speech Recognition with the Quill instance
        initializeSpeechRecognition(chatinputbox);

        // Function to detect and remove deleted images
        function detectAndRemoveDeletedImages(delta, oldDelta) {
            // Get all current image sources in the editor
            let currentImages = Array.from(chatinputbox.root.querySelectorAll('img')).map(img => img.src);

            // Get all old image sources before the change
            let oldImages = oldDelta.ops
                .filter(op => op.insert && op.insert.image)
                .map(op => op.insert.image);

            // Find the first image that was removed
            oldImages.forEach((oldSrc, index) => {
                if (!currentImages.includes(oldSrc)) {
                    // Remove the corresponding job ID from the array
                    removeSavedJobId(index, 1);
                    logger.log(`Job ID at index ${index} removed after image deletion`);
                }
            });
        }

        // Event listener to detect image deletion and handle cursor movements
        chatinputbox.on('text-change', function (delta, oldDelta, source) {
            if (source === 'user') {
                detectAndRemoveDeletedImages(delta, oldDelta);
            }
        });

        chatinputbox.on('selection-change', function (range, oldRange, source) {
            if (source === 'user') {
                if (range === null || oldRange === null) {
                    return; // If the selection is null, skip processing
                }
                if (range.length === 0 && oldRange.length === 0) {
                    // Cursor movement detected
                    detectAndRemoveDeletedImages({ops: []}, chatinputbox.getContents());
                }
            }
        });

        chatinputbox.root.addEventListener('keydown', function (event) {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                // Backspace or Delete key detected
                detectAndRemoveDeletedImages({ops: []}, chatinputbox.getContents());
            }
        });


        if (getMobileOperatingSystem() === 'ios') {
            var chat_ui_container = document.querySelector(".chat-ui-container");
            chat_ui_container.style.setProperty('max-height', ((window.innerHeight - height_padding) + "px"));

            var chat_container = document.getElementById("chat-block");
            chat_container.style.setProperty('max-height', ((window.innerHeight - height_padding) + "px"));

            qlchatinputbox.addEventListener('focus', () => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }, false);

            qlchatinputbox.addEventListener('focusout', () => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }, false);
        }

        examplesCarouselLoad();
        addCarouselToggleEventHandler('carouselExamplesToggle');
        fromCookieValueCarouselExamples();

        chatinputbox.focus();

        // Image upload and paste handling
        function selectLocalImage(files = null) {
            if (!files) {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.setAttribute('multiple', 'multiple');
                input.click();

                input.onchange = () => {
                    handleFiles(input.files, false);
                };
            } else {
                handleFiles(files, true);
            }
        }

        function handleFiles(files, pasted = false) {
            const $submitbutton = $('#chat-submit');
            const $attachmentButton = $('#attachmentButton');
            $submitbutton.addClass('disabled');
            $attachmentButton.addClass('disabled');

            pendingUploads += files.length; // Increment counter for each file
            logger.log("PENDING ATTACHMENT UPLOADS", pendingUploads);

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (/^image\//.test(file.type)) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const editorContent = chatinputbox.getContents();

                        // Find the index after the last image in the editor
                        const lastImageIndex = editorContent.ops
                            .map((op, idx) => ({op, idx}))
                            .filter(item => item.op.insert && item.op.insert.image)
                            .map(item => item.idx)
                            .pop();

                        let insertPosition;
                        if (lastImageIndex !== undefined) {
                            // If not the first image, insert after the last image
                            insertPosition = lastImageIndex + 1;
                        } else {
                            // If this is the first image, insert at the beginning
                            insertPosition = 0;
                        }

                        // Insert the image at the determined position
                        chatinputbox.insertEmbed(insertPosition, 'image', e.target.result);

                        // Remove the newline after the first image
                        chatinputbox.deleteText(insertPosition + 1, 1);

                        // Add a newline after inserting the image
                        chatinputbox.insertText(insertPosition + 1, '\n');

                        // Move the cursor to the end of the editor content
                        const length = chatinputbox.getLength();
                        chatinputbox.setSelection(length, length);

                        uploadImageToS3(file, (error, signedPost) => {
                            if (error) {
                                logger.error('Failed to upload image:', error);
                                pendingUploads--; // Decrement counter on error
                                checkPendingUploads(pendingUploads);
                                return;
                            }

                            const s3Url = `${signedPost.url}/${signedPost.fields.key}`;
                            logger.log("S3 URL - Successfully Uploaded to S3", s3Url);
                            addJobId(signedPost.job_id);

                            pendingUploads--; // Decrement counter after successful upload
                            checkPendingUploads(pendingUploads);
                        });
                    };
                    reader.readAsDataURL(file);
                } else {
                    logger.warn('You can only upload images.');
                    pendingUploads--; // Decrement counter if file type is invalid
                    checkPendingUploads(pendingUploads);
                }
            }
        }


        function checkPendingUploads(pendingUploads) {
            logger.log("CHECKING PENDING UPLOADS", pendingUploads)
            if (pendingUploads === 0) {
                // All uploads complete, enable the submit button
                $('#chat-submit').removeClass('disabled');
                $('#attachmentButton').removeClass('disabled');
            }
        }

        function showErrorMessage(message) {
            $error.text(message);
            $error.show();
            setTimeout(() => {
                $error.hide();
            }, 3000);
        }

        const attachmentButton = document.getElementById('attachmentButton');
        if (attachmentButton) {
            attachmentButton.addEventListener('click', (e) => {
                if (attachmentButton.classList.contains('disabled')) {
                    // If the button is disabled, prevent any action
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
                e.preventDefault();
                selectLocalImage();
            });
        }

        chatinputbox.clipboard.addMatcher('IMG', (node, delta) => {
            fetch(node.getAttribute('src'))
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "pasted-image.png", {type: blob.type});
                    selectLocalImage([file]);

                })
                .catch(error => {
                    logger.error('Failed to process pasted image:', error);
                    pendingUploads--; // Decrement counter on error
                    checkPendingUploads(pendingUploads);
                });

            return delta;
        });
    }
}

export function addChatHistory(chat_history = chat_history, version = 0, document_title = 'AI Chat 2') {
    try {
        if (chat_history != null) {
            hideexamplescarousel('carouselExamples_desktop');
            hideexamplescarousel('carouselExamples_mobile');
            hidecontrolwithdflex('chat-header');
            var $chatContainer = $('#chat-container');

            var $chatitemtpl = version === 0 ? $('#chat-item-tpl') : $('#chat-item-tpl-v2');

            logger.log('chat_history: ' + chat_history);
            chat_history.forEach(chat_item => {
                let chat_tmp_texts = [];
                let chat_tmp_files = [];

                // Loop through each content item and collect texts and files
                if (Array.isArray(chat_item.content)) {
                    chat_item.content.forEach(item => {
                        if (item.text) {
                            let parsed_text = document_title === 'AI Chat 2' ? marked.parse(item.text) : item.text;
                            chat_tmp_texts.push(parsed_text);
                        }
                        if (item.file?.url) {
                            chat_tmp_files.push(item.file.url);
                        }
                    });
                } else {
                    let parsed_text = document_title === 'AI Chat 2' ? marked.parse(chat_item.content) : chat_item.content;
                    chat_tmp_texts.push(parsed_text);
                }

                if (chat_item.role !== 'system') {
                    const data = {
                        'id': chat_item.role + '_old',
                        'texts': chat_tmp_texts, // Array of all texts
                        'image': `/images/${chat_item.role}${chat_item.role === 'user' ? '.svg' : '.png'}`,
                        'background': '#FFFFFF',
                        'files': chat_tmp_files // Array of all file URLs
                    };

                    logger.log('before render')
                    const cardOutput = $chatitemtpl.render({'data': data});

                    // Add the rendered template to the chat container
                    $chatContainer.append(cardOutput);
                }
            });

            // Scroll to the bottom of the chat container
            $chatContainer.scrollTop($chatContainer.prop('scrollHeight'));
        }
    } catch (error) {
        logger.error("Error in addChatHistory: ", error);

    }
}
