var defaultOpts = {
    angle: 0, // The span of the gauge arc
    lineWidth: 0.44, // The line thickness
    radiusScale: 1, // Relative radius
    pointer: {
        length: 0.6, // // Relative to gauge radius
        strokeWidth: 0.035, // The thickness
        color: '#000000' // Fill color
    },
    limitMax: false,     // If false, max value increases automatically if value > maxValue
    limitMin: false,     // If true, the min value of the gauge will be fixed
    colorStart: '#ea0606',   // Colors
    colorStop: '#b90404',    // just experiment with them
    strokeColor: '#E0E0E0',  // to see which ones work best for you
    generateGradient: true,
    highDpiSupport: true,
    staticLabels: {
        font: "12px open sans",  // Specifies font
        labels: [0, 100],  // Print labels at these values
        color: "#000000",  // Optional: Label text color
        fractionDigits: 0  // Optional: Numerical precision. 0=round off.
    },// High resolution support

};

export var aiScoreGauge = function (type, score, description) {
    // console.log(score)
    var aiScoreUI = document.getElementById('ai-score-' + type)

    if (aiScoreUI) {

        aiScoreUI.classList.remove('d-none');
        aiScoreUI.classList.add('d-block');

        const scoreElement = aiScoreUI.querySelector('#ai-score-score-' + type)
        if (scoreElement) {
            // console.log(score)
            scoreElement.countTo = score;
            const countUp = new CountUp('ai-score-score-' + type, score);
            if (!countUp.error) {
                countUp.start();
            } else {
                // console.error(countUp.error);
            }
        }

        const descriptionElement = aiScoreUI.querySelector('#ai-score-description')
        if (descriptionElement) {
            descriptionElement.textContent = description
        }

        const gagueElement = document.getElementById('ai-score-gauge-' + type)
        if (gagueElement) {
            var gauge = new Gauge(gagueElement).setOptions(defaultOpts); // create sexy gauge!
            gauge.maxValue = 100; // set max gauge value
            gauge.setMinValue(0);  // Prefer setter over gauge.minValue = 0
            gauge.setOptions({
                staticLabels: {
                    font: "10px sans-serif",  // Specifies font
                    labels: [0, score, 100],  // Print labels at these values
                    color: "#000000",  // Optional: Label text color
                    fractionDigits: 0  // Optional: Numerical precision. 0=round off.
                }
            })
            if (type === 'ai_humaniser') {
                gauge.setOptions({
                    colorStart: '#82d616',
                    colorStop: '#6aaf13',
                })
            }

            gauge.animationSpeed = 32;
            if (score > 0) {
                // set animation speed (32 is default value)
                gauge.set(score);
            } else {
                // if the score is 100 it gauge doesn't animate so we set it to 100 then animate back to zero

                //use green for a positive value
                gauge.setOptions({
                    colorStart: '#82d616',
                    colorStop: '#6aaf13',
                })
                // 1 is basically no animation of the gauge
                gauge.animationSpeed = 1
                // start the gauge at 100
                gauge.set(100);
                // put the animation speed back.
                gauge.animationSpeed = 32

                // short timeout otherwise you gauge updates too fast.
                setTimeout(function () {
                    // console.log('timeout gauge')
                    // console.log(score)
                    gauge.set(0);


                }, 400); // Set delay to 400 milliseconds
            }


            aiScoreUI.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            });

        }
    }
}




