import {descriptionbox, outputbox, selectiontext} from "./copywriting";
import {error_response} from "../app-basic";
import {pusher} from "./extensions";
import {addHistoryItem} from "./history";
import {
    hideExamples, hidecontrolwithdflex, hideexamplescarousel, hidecontrol
} from "./promptExamples";
import {
    processAIResult,
    processAIResultStream,
    processAIStreamMessage,
    user_scrolling,
    reset_typerange, reset_systemScroll, reset_toConvert, systemScroll
} from "./copywriting-result";
import {reset_loadinterval, addChatItem, chatinputbox, loaderdots, clearJobIds, savedJobIds, saveEditorContent, getSavedEditorContent, restoreEditorContent} from "./chat";
import {aiScoreGauge} from "./aiScore";
import throttle from "./throttle";
import {stopTextSelection, upgradeRequired} from "./upgrade-required";
import {reset_used_speech_recognition, speech_recognition, used_speech_recognition} from "./speech-recognition";
import assistantManager from './assistant'
import { customLogger } from './logger.js';
const logger = customLogger('copywriting-post', 'cyan');








export var postCopy = function (event = null, action = null, form = 'formCopywriting', show_title = true, select = true, job = false) {
    logger.log('postCopy')
    var $formCopywriting = $('#' + form);
    var $spinner = $('#copy-spinner');
    var $submitbutton = $('.csubmit');
    var $btnText = $('.btnText');
    var $btnTextAction = $('.btnTextAction');
    var $result = $('#editor');
    // console.log('form: ' + form)
    $spinner.removeClass('d-none');
    $submitbutton.addClass('disabled');
    $btnText.addClass('d-none');
    $btnTextAction.removeClass('d-none');
    $result.removeClass('border border-success')
    $result.removeClass('border border-danger')
    $result.addClass('border border-warning')

    var url = $formCopywriting.attr('action');

    // var instruction = $('#instruction-options').length > 0 ? $('#instruction-options')[0].value : action;
    var $instructionOptions = $('#instruction-options');
    var instruction = $instructionOptions.length ? $instructionOptions[0].value : action;
    var $keywords = $('#keyword-tags');
    var $aicopy = $('#aicopy');
    var $description = $('#description');

    var $remainingText = $('#remainingText');
    var $progressPercent = $('#progressPercent');

    var selectedtext = selectiontext;
    var size = $('#chat-toggle').find('i').data('icon')

    var $error = $('#form_error');
    $error.removeClass('alert-danger')
    $error.addClass('alert-warning')
    $error.text('Wilfie is working his AI magic...')
    $error.show()

    if (action == null) {
        action = 'default' //event.originalEvent.submitter.value
    }


    var data = {
        'instruction_id': instruction,
        'keywords': ($keywords && $keywords.length > 0) ? $keywords[0].value : '',
        'instruction_input': descriptionbox.getText(),
        'sub_instruction_id': action,
        'sub_instruction_input': selectedtext,
        'job': job,
        'size': size
    }

    // sizes 1024x1024, 1024x1792 portrait  or 1792x1024 landscapes


    return $.ajax({
        type: 'post',
        url: url,
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        processData: false,
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-TOKEN', $.cookie('csrf_access_token'));
            if (action == "ai_video") {

                $('#popupVideo').modal('show')

                var $progressdiv = $('#progressdiv');
                $progressdiv.show()

                var $video = $('#videoresult');
                $video[0].removeAttribute('src')
                $video[0].load();

                var $updatemessage = $('#update-message');
                $updatemessage.text("Creating Video Script")
                var $progressbar = $('#progressbar');
                if ($progressbar.length) {
                    var progress_value = 0.05 + '%'
                    $progressbar[0].style.width = progress_value;

                }


            }
        }
        ,
        success: function (response, status, xhr) {

            if (!job) {
                $error.hide();
                $aicopy[0].className = 'd-block';
                $result.removeClass('border border-warning')
                $result.addClass('border border-success')
                processAIResult(response, show_title, select)
            } else if (action == "ai_image" || action == "ai_video") {
                if (response.data.remaining >= 0) {
                    $error.text("Wilfie is working his AI magic... " + response.data.counterText)
                } else {
                    var $assistantdots = $('#assistantdots');
                    var $assistantcard = $('#chat-card-assistant');
                    reset_loadinterval()
                    $assistantdots[0].innerText = '';
                    $assistantcard.remove();
                    $error.addClass('alert-danger')
                    $error.removeClass('alert-warning')
                    $error.text(response.data.counterText)
                }

            }

            $spinner.addClass("d-none");
            $btnText.removeClass('d-none');
            $btnTextAction.addClass('d-none');

            $submitbutton.removeClass('disabled');
            if ($remainingText && $remainingText.length > 0) {
                $remainingText[0].innerText = response.data.counter;
                $progressPercent[0].style.width = response.data.counter_percent
            }
            if (action == "ai_image") {
                var privateAppChannel = pusher.subscribe('private-jobid-' + response.data.job_id);
                pusherTextImageAudioMessage(privateAppChannel, 'message')
            } else if (action == "ai_video") {
                var privateAppChannel = pusher.subscribe('private-jobid-' + response.data.job_id);
                pusherVideoMessage(privateAppChannel, 'message')

            }

        }
        ,
        error: function (response, status, xhr) {
            // Restore the saved content in case of an error
            const savedContent = getSavedEditorContent();
            if (savedContent) {
                descriptionbox.setContents(savedContent);
            }

            if ($aicopy && $aicopy.length > 0) {
                $aicopy[0].className = 'd-block';
            }
            $result.removeClass('border border-warning')
            $result.addClass('border border-danger')
            $spinner.addClass("d-none");
            $btnText.removeClass('d-none');
            $btnTextAction.addClass('d-none');
            $submitbutton.removeClass('disabled');
            $error.text(error_response(response));
            $error.removeClass('alert-warning')
            $error.addClass('alert-danger')
            $error.show();            
        }        
    });
}

export var postCopyStream = function (event = null, action = null, form = 'formCopywriting', show_title = true, select = true, job = false) {
    // console.log('form: ' + form)
    // var $formCopywriting = $('#' + form);
    logger.log('postCopyStream')
    var $spinner = $('#copy-spinner');
    var $submitbutton = $('.csubmit');
    var $btnText = $('.btnText');
    var $btnTextAction = $('.btnTextAction');
    if (form == 'formCopywriting') {
        var $result = $('#text-edit-card');
    } else {
        var $result = $('#editor');
    }

    $spinner.removeClass('d-none');
    $submitbutton.addClass('disabled');
    $btnText.addClass('d-none');
    $btnTextAction.removeClass('d-none');

    $result.removeClass('border border-success')
    $result.removeClass('border border-danger')
    $result.addClass('border border-warning')


    var $instruction = $('#instruction-options');
    var $keywords = $('#keyword-tags');
    var $aicopy = $('#aicopy');
    var $description = $('#description');

    var $remainingText = $('#remainingText');
    var $progressPercent = $('#progressPercent');

    var selectedtext = selectiontext;
    var $upgrade_required_stop_text_selection = $('#upgrade-required-stop-text-selection')

    var $error = $('#form_error');
    $error.removeClass('alert-danger')
    $error.addClass('alert-warning')
    $error.text('Wilfie is working his AI magic...')
    $error.show()

    if (action == null) {
        action = 'default' //event.originalEvent.submitter.value
    }


    var data = {
        'instruction_id': $instruction[0].value,
        'keywords': ($keywords && $keywords.length > 0) ? $keywords[0].value : '',
        'instruction_input': descriptionbox.getText(),
        'sub_instruction_id': action,
        'sub_instruction_input': selectedtext,
        'job': job,
        'response_format': 'sse'
    }

    const queryString = Object.keys(data).map(key => {
        const value = encodeURIComponent(data[key]);
        return `${encodeURIComponent(key)}=${value}`;
    }).join('&');

    const url = `/api/v1/copywriting/ai-stream?${queryString}`;

    var input = descriptionbox.getText()


    if (action == null) {
        action = 'default' //event.originalEvent.submitter.value
    }
    reset_typerange()
    reset_toConvert()
    var messages_count = 0
    var eventSource = new EventSource(url);

    eventSource.addEventListener('close', function (event) {


        eventSource.close();
        reset_loadinterval()


        $spinner.addClass("d-none");
        $btnText.removeClass('d-none');
        $btnTextAction.addClass('d-none');


        // $assistantdots[0].innerText = '';
        $error.text('')
        // $assistantcard[0].id = 'pastcard';
        // $assistant[0].id = 'pasttext';
        // $assistantdots[0].id = 'pastdots'
        $submitbutton.removeClass('disabled');
        // $btnStop.addClass('d-none')
        $submitbutton.removeClass('d-none');
        $result.removeClass('border border-warning')
        $result.addClass('border border-success')
        //
        // $assistantcard.removeClass('border-warning')
        // $chatinputbox.addClass('border-primary')
        // chatinputbox.enable(true);
        // chatinputbox.focus();

    }, false);

    eventSource.addEventListener('counter', function (event) {
        try {
            // console.log('history received:');
            var response = JSON.parse(event.data);

            if ($remainingText && $remainingText.length > 0) {
                $remainingText[0].innerText = response.data.counter;
                $progressPercent[0].style.width = response.data.counter_percent
            }

        } catch (e) {
            console.error('Error parsing history JSON:', e);
        }

    }, false);


    eventSource.addEventListener('subscription_required', function (event) {
        try {
            var response = JSON.parse(event.data);
            if (response.data.start === true) {
                stopTextSelection()
            } else {
                upgradeRequired();
            }
        } catch (e) {
            console.error('Error parsing history JSON:', e);
        }
    }, false);


    eventSource.addEventListener('ai_score', function (event) {
        try {
            // console.log('history received:');
            var response = JSON.parse(event.data);

            aiScoreGauge(response.data.type, response.data.score, response.data.description)

        } catch (e) {
            console.error('Error parsing history JSON:', e);
        }

    }, false);

    eventSource.addEventListener('history', function (event) {
        try {
            // console.log('history received:');
            var response = JSON.parse(event.data);
            addHistoryItem(response.data)
            // var response = JSON.parse(event.data);
            // var $history_code = $('#chat-history-code');
            // $history_code[0].value = response.data.history_code

        } catch (e) {
            console.error('Error parsing history JSON:', e);
        }

    }, false);

    eventSource.addEventListener('title', function (event) {
        try {

            // console.log('title received')
            var response = JSON.parse(event.data);
            // Update UI after appending the new message
            // Add the text to the queue of messages to print
            // console.log(typerange)
            processAIResultStream(response, show_title, false)
            // console.log(typerange)


        } catch (e) {
            console.error('Error parsing JSON:', e);
        }

    }, false);

    eventSource.onmessage = function (event) {
        messages_count += 1;
        //update buttons when first message arrives
        if (messages_count == 1) {

            $aicopy[0].className = 'd-block';
            // $btnStop.removeClass('d-none');
            // $submitbutton.addClass('d-none');
            $error.hide();
            $spinner.addClass("d-none");
            $btnText.removeClass('d-none');
            $btnTextAction.addClass('d-none');
            if (form !== 'formCopywriting') {
                // console.log('scroll copy to view')
                $aicopy[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'end'
                });


            }
        }
        try {


            var response = JSON.parse(event.data);
            // Update UI after appending the new message
            processAIStreamMessage(response, false)


        } catch (e) {
            console.error('Error parsing JSON:', e);
        }

    };

    eventSource.onerror = function (event) {
        logger.log("EVENT ERROR - POST COPY", event);
        if (event.currentTarget && event.currentTarget.readyState !== 2 || event.type == "error") {
            logger.log("EVENT CURRENT TARGET", event.currentTarget);
            logger.log("EVENT CURRENT TARGET - READY STATE", event.currentTarget.readyState);
            logger.log("EVENT TYPE", event.type);

            eventSource.close();
    
            $result.removeClass('border border-warning')
            $result.addClass('border border-danger')
            $spinner.addClass("d-none");
            $btnText.removeClass('d-none');
            $btnTextAction.addClass('d-none');
            $submitbutton.removeClass('disabled');
            try {
                var error_data = JSON.parse(event.data);
                $error.text(error_data.data.error_message);
                if (error_data.data.error_type == 'Info') {
                    $error.removeClass('alert-danger')
                    $error.addClass('alert-info')
                } else {
                    $error.addClass('alert-danger')
                }
            } catch (e) {
                $error.text('Wilfie is very busy right now, Please try again later');
                $error.addClass('alert-danger');
            }
            $error.removeClass('alert-warning')
            $error.show();            
        }
    };


}


export var AICopywriting = function (action = null, form_name = 'formCopywriting', stream = false) {
    var $form = $('#' + form_name);

    function performActions() {
        event.preventDefault();

        // Check if the screen width is less than 767.98 pixels (md size)
        if (window.innerWidth < 767.98) {
            var leftPanel = document.querySelector('.left-panel');
            if (leftPanel) {
                leftPanel.classList.remove('show')
            }
        }
        if (stream) {
            postCopyStream(event, null, form_name, true, false);
            // console.log('stream request')
        } else {
            postCopy(event);
            // console.log('post request')
        }
    }

    $form.on('submit', function (event) {
        performActions()
    });

    $form.on('keydown', function (event) {
        if (event.keyCode === 13 && !event.shiftKey) {
            if (event.target.offsetParent.id == 'description') {
                performActions()
            }
        }
    });
}


export var AIAssistant = function (action = null, form_name = 'formAssistant', stream = false, examples = true, hide_previous_result = false) {

    var $form = $('#' + form_name);

    function performActions() {
        if (event) {
            event.preventDefault();
        } else {
            event = null
        }

        outputbox.setContents([])
        if (examples) {
            hideExamples('carouselExamples_desktop')
        }
        if (hide_previous_result) {
            var previous_result = document.getElementById("aicopy")
            if (previous_result) {
                if (previous_result.classList.contains('d-block')) {
                    previous_result.classList.remove('d-block');
                    previous_result.classList.add('d-none');
                }
            }
        }
        if (stream) {
            postCopyStream(event, null, form_name, false, false);
            // console.log('stream request')
        } else {
            postCopy(event, null, form_name, false, false);
            // console.log('post request')
        }


    }

    $form.on('submit', function (event) {
        performActions()
    });

    $form.on('keydown', function (event) {
        if (event.keyCode === 13 && !event.shiftKey) {
            performActions()
        }
    });

    if (doc.name != "" && doc.name !== undefined) {
        if (doc.submit === true) {
            performActions()
        }
    }

};

export var AITextImageAudio = function (form, action = null) {
    var $form = $('#' + form);

    function performActions() {
        hideExamples('carouselExamples_desktop');
        hideExamples('carouselExamples_mobile');
        hidecontrol('pageheader');
        addChatItem(descriptionbox.getText());

        var $assistantdots = $('#assistantdots');
        loaderdots($assistantdots, '')

        postCopy(null, 'ai_image', form, false, false, true);
        // postTextImageAudios(null, null, form, false, false);
        descriptionbox.setContents([]);
    }

    $form.on('submit', function (event) {
        event.preventDefault();
        performActions();
    });

    $form.on('keyup', function (event) {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            performActions();
        }
    });

};

export var AIChatStream = function (action = null) {
    var $formChatStream = $('#formChatStream');
    var $chatContainer = $('#chat-container');


    function performActions() {

        if (event.submitter && event.submitter.id === 'btn-stop') {
            postStopAIStream()
        } else {
            speech_recognition.stop()
            hideexamplescarousel('carouselExamples_desktop')
            hideexamplescarousel('carouselExamples_mobile')
            hidecontrolwithdflex('chat-header')
            addChatItem(chatinputbox.getText())
            reset_systemScroll()
            reset_toConvert();
            postChatsStream(event, null, 'formChat', false, false);
            logger.debug('clear contents')
            chatinputbox.setContents([])
            reset_used_speech_recognition() //reset for data collection
        }
    }

    $formChatStream.on('submit', function (event) {
        event.preventDefault();
        performActions()
    });

    $formChatStream.on('keyup', function (event) {
        const chatSubmitButton = $('#chat-submit');
        if (event.keyCode === 13 && !event.shiftKey && !chatSubmitButton.hasClass('disabled')) {
            event.preventDefault();
            performActions()
        }
    });
    
    // Detect when the user is scrolling and update the flag accordingly.
    $chatContainer.on('scroll', throttle(function () {
        if (systemScroll) {
            user_scrolling($chatContainer);
        }
    }, 50)); // Adjust the throttle time


};


const inputHistory = [];
let historyIndex = 0;

export var AIVideo = function (action = null) {
    var $formVideo = $('#formVideo');


    $formVideo.on('submit', function (event) {
        event.preventDefault();


        postCopy(event, 'ai_video', 'formVideo', false, false, true);

    });

    $formVideo.on('keydown', function (event) {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            postCopy(event, 'ai_video', 'formVideo', false, false, true);
        }
    });
};

async function postStopAIStream() {
    const url = "/api/v1/copywriting/stop-ai-stream"
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": $.cookie("csrf_access_token")
        },
        // body: JSON.stringify({
        //     // Add any required fields for the request here
        // }),
    };

    try {
        const response = await fetch(url, requestOptions);
        if (response.ok) {
            var $submitbutton = $('#chat-submit');
            var $btnStop = $('#btn-stop');
            var $chatinputbox = $('#chatinputbox');
            $btnStop.addClass('d-none');
            $submitbutton.removeClass('d-none')
            $chatinputbox.addClass('border-primary')
            chatinputbox.focus()
            const responseData = await response.json();
            // console.log("Request successful", responseData);
        } else {
            // console.log("Request failed with status:", response.status);
        }
    } catch (error) {
        console.error("Error in Request:", error);
    }
}

var postChatsStream = function (event = null, action = null, form = 'formChatStream', title = true, select = true, video = false) {
    logger.log('postChatsStream')
    var $history_code = $('#chat-history-code');
    var $spinner = $('#chat-spinner');
    var $submitbutton = $('#chat-submit');
    var $btnText = $('#chat-btn-text');
    var $btnStop = $('#btn-stop');

    var $btnTextAction = $('.chat-btn-textaction');
    var $assistant = $('#assistant');
    var $assistantdots = $('#assistantdots');

    var $assistantcard = $('#chat-card-assistant');
    var $usercard = $('#chat-card-user');
    var $chatinputbox = $('#chatinputbox');

    $spinner.removeClass('d-none');
    $submitbutton.addClass('disabled');
    $btnText.addClass('d-none');

    $assistantcard.addClass('border border-warning');
    chatinputbox.enable(false);

    const descriptionText = chatinputbox.getText();
    const quillContent = chatinputbox.getContents(); // Get the full Quill content (including images)

    // Save the Quill content and jobIds before submitting
    saveEditorContent(chatinputbox);

    const sendRequest = () => {
        logger.debug("JOB IDs", savedJobIds);
        logger.log('used_speech_recognition: ' + used_speech_recognition)
        const event_data_str = JSON.stringify({"used_speech_recognition": used_speech_recognition});
        const data = {
            'instruction_id': 'ai_chat',
            'keywords': '',
            'instruction_input': descriptionText.substring(0, 3000),
            'sub_instruction_id': 'ai_chat',
            'sub_instruction_input': '',
            'job': false,
            'history_code': $history_code[0].value,
            'job_ids': savedJobIds, // Use the last Job ID as an array
            'event_data': event_data_str,
            'assistant_id': assistantManager.getSelectedAssistantId(),
            'response_format': 'sse'
        };
        logger.log('assistant_id: ' + assistantManager.getSelectedAssistantId())

        const doc_code_value = $('meta[name=doc_code]').attr('content');
        if (doc_code_value !== '') {
            data['doc_code'] = doc_code_value;
        }

        const queryString = Object.keys(data).map(key => {
            if (Array.isArray(data[key])) {
                return data[key].map(value => {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                }).join('&');
            } else {
                const value = encodeURIComponent(data[key]);
                return `${encodeURIComponent(key)}=${value}`;
            }
        }).join('&');

        const url = `/api/v1/copywriting/ai-stream?${queryString}`;

        var input = chatinputbox.getText();

        var $error = $('#chat-form_error');
        $error.hide();

        loaderdots($assistantdots, '.');

        if (action == null) {
            action = 'default';
        }

        var messages_count = 0;
        var $chatContainer = $('#chat-container');

        var eventSource = new EventSource(url);
        eventSource.addEventListener('close', function (event) {
            eventSource.close();
            reset_loadinterval();
            $assistantdots[0].innerText = '';
            $error.text('');
            $assistantcard[0].id = 'pastcard';
            $assistant[0].id = 'pasttext';
            $assistantdots[0].id = 'pastdots';
            $submitbutton.removeClass('disabled');
            $btnStop.addClass('d-none');
            $submitbutton.removeClass('d-none');
            $assistantcard.removeClass('border-warning');
            $chatinputbox.addClass('border-primary');
            chatinputbox.enable(true);
            chatinputbox.focus();
        }, false);

        eventSource.addEventListener('history', function (event) {
            try {
                var response = JSON.parse(event.data);
                var $history_code = $('#chat-history-code');
                $history_code[0].value = response.data.history_code;
            } catch (e) {
                console.error('Error parsing history JSON:', e);
            }
        }, false);

        eventSource.onmessage = function (event) {
            messages_count += 1;
            try {
                var response = JSON.parse(event.data);
                processAIStreamMessage(response, true, $assistant, $chatContainer);
                clearJobIds();
            } catch (e) {
                console.error('Error parsing JSON:', e);
            }
            if (messages_count == 1) {
                $error.hide();
                $btnStop.removeClass('d-none');
                $submitbutton.addClass('d-none');
                $spinner.addClass("d-none");
                $btnText.removeClass('d-none');
                $btnTextAction.addClass('d-none');
                $chatinputbox.removeClass('border-primary');
            }
        };

        eventSource.onerror = function (event) {
            console.log("EVENT ERROR - POST CHAT STREAM", event);

            if (event.currentTarget && event.currentTarget.readyState !== 2 || event.type == "error") {
                console.log("EVENT CURRENT TARGET", event.currentTarget);
                console.log("EVENT CURRENT TARGET - READY STATE", event.currentTarget.readyState);
                console.log("EVENT TYPE", event.type);
                eventSource.close();
                $submitbutton.removeClass('d-none');
                $submitbutton.removeClass('disabled');
                $btnStop.addClass('d-none');
                $assistantcard.remove();
                $usercard.remove();

                try {
                    var error_data = JSON.parse(event.data);
                    $error.text(error_data.data.error_message);
                    if (error_data.data.error_type == 'Info') {
                        $error.removeClass('alert-danger');
                        $error.addClass('alert-info');
                    } else {
                        $error.addClass('alert-danger');
                    }
                } catch (e) {
                    $error.text('Wilfie is very busy right now, Please try again later');
                    $error.addClass('alert-danger');
                }

                $error.removeClass('alert-warning');
                $error.show();

                var $chatContainer = $('#chat-container');
                $chatContainer.scrollTop = $chatContainer.scrollHeight;

                // Bring Back Send Button After Error for Resending
                $btnText.removeClass('d-none');
                $btnTextAction.addClass('d-none');

                // Restore the saved content and jobIds in case of an error
                console.log("RESTORING SAVED CONTENT AND JOB IDs", chatinputbox);
                restoreEditorContent(chatinputbox);

                chatinputbox.enable(true);
                chatinputbox.focus();
            }
        };
    };

    sendRequest();
};



var pusherTextImageAudioMessage = function (channel, event) {
    channel.bind(event, function (data) {
        var $assistant = $('#assistant');
        var $assistantcard = $('#chat-card-assistant');
        var $assistantdots = $('#assistantdots');
        var $image = $('#assistantimage');
        var $chatContainer = $('#chat-container');
        var $error = $('#form_error');

        if (data.message.messages[0].result != null) {
            $error.hide();
            $assistantdots[0].innerText = '';
            reset_loadinterval();

            $image.on('load', function () {
                $image[0].style.display = 'block';
                $image[0].id = 'pastimage';
                $assistantcard[0].id = 'pastcard';
                $assistant[0].id = 'pasttext';
                $assistantdots[0].id = 'pastdots';
                $chatContainer.scrollTop($chatContainer.prop('scrollHeight'));
            });

            $image[0].src = data.message.messages[0].result;
        }
    });
};

export var pusherJobMessage = function (channel, event) {
    channel.bind(event, function (data) {

        if (data.message.messages[0].result != null) {

            var result = data.message.messages[0].result
            $('#upload-widget').fadeOut(2000, function () {
                // This code will run after the fadeOut operation is done.
                // You can now display your #aicopy.s
                var $divupload = $('#div-upload')
                $divupload[0].className = "d-none";
                var $aicopy = $('#aicopy');
                $aicopy[0].className = 'd-block';
                var response =
                    {
                        "data": {
                            "title": "",
                            "header": "H1",

                            "position": "",
                            "result": result
                        }
                    }

                processAIResult(response, false, false, false, true)

            });


        } else {
            var message = data.message.messages[0].message
            $('[data-dz-name]').text(message);
            $('[data-dz-size]').text("");

        }
    });
};



var pusherVideoMessage = function (channel, event) {


    channel.bind(event, function (data) {
        $('#popupVideo').modal('show')

        var $progressdiv = $('#progressdiv');

        $progressdiv.show()

        var $video = $('#videoresult');
        if (data.message.messages[0].result != null) {
            $video[0].src = data.message.messages[0].result
            $video[0].load();
        } else {
            $video[0].removeAttribute('src')
            $video[0].load();
            // $video[0].src = null
        }


        var $updatemessage = $('#update-message');
        $updatemessage.text(data.message.messages[0].message)
        var $progressbar = $('#progressbar');
        if ($progressbar.length) {
            var progress_value = (data.message.messages[0].progress * 100) + '%'
            $progressbar[0].style.width = progress_value;
            //$progressbar.value = 40 //data.message.messages[0].progress
        }

        var $progressdiv = $('#progressdiv');
        if (data.message.messages[0].progress == 1) {
            $progressdiv.hide()
        }

    });
};

// Helper function to convert data URL to File object
function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
