import {toggleKeywords, toggleGroups, toggleCustomTags} from './cookies.js';
import {toggleExampleCarousel} from "./promptExamples";
import logger from "./logger";

export function addKeywordsToggleEventHandler(clickedElementId) {
    var clickedElementId = document.getElementById(clickedElementId);
    if (clickedElementId) {
        clickedElementId.addEventListener('click', function() {
            // Properly reference the function
            toggleKeywords();
        });
    } else {
        logger.warn('Element with ID ' + clickedElementId + ' not found. addKeywordsToggleEventHandler');
    }
}

export function addGroupsToggleEventHandler(clickedElementId) {
    var clickedElementId = document.getElementById(clickedElementId);
    if (clickedElementId) {
        clickedElementId.addEventListener('click', function() {
            // Properly reference the function
            toggleGroups();
        });
    } else {
        logger.warn('Element with ID ' + clickedElementId + ' not found. addGroupsToggleEventHandler');
    }
}


export function addTagsToggleEventHandler(clickedElementId) {
    var clickedElementId = document.getElementById(clickedElementId);
    if (clickedElementId) {
        clickedElementId.addEventListener('click', function() {
            // Properly reference the function
            toggleCustomTags();
        });
    } else {
        logger.warn('Element with ID ' + clickedElementId + ' not found. addTagsToggleEventHandler');
    }
}

export function addCarouselToggleEventHandler(clickedElementId) {
    var clickedElementId = document.getElementById(clickedElementId);
    if (clickedElementId) {
        clickedElementId.addEventListener('click', function() {
            toggleExampleCarousel(); // Properly reference the function
        });
    } else {
        logger.warn('Element with ID ' + clickedElementId + ' not found. addCarouselToggleEventHandler');
    }
}