export var upgradeRequired = function (){

    var upgradeRequiredDiv = document.getElementById('upgrade-required')

    if (upgradeRequiredDiv) {

        var aiCopyOutput = document.getElementById("editor")
        if (aiCopyOutput) {
            // blur the text output
            aiCopyOutput.style.filter = "blur(3px)";
        }

        var aiCopyContainer = document.getElementById("scrolling-container")
        if (aiCopyContainer) {
            //add the Subscription box overlay
            aiCopyContainer.prepend(upgradeRequiredDiv);
        }
        // un hide the Subscription box overlay
        upgradeRequiredDiv.classList.remove('d-none');
        upgradeRequiredDiv.classList.add('d-block');
    }
}

export var stopTextSelection = function () {

    var stopTextSelection = document.getElementById('upgrade-required-stop-text-selection')

    if (stopTextSelection) {

        var aiCopyContainer = document.getElementById("scrolling-container")
        if (aiCopyContainer) {
            //add the empty overlay
            aiCopyContainer.prepend(stopTextSelection);
        }
        // show the box overlay
        stopTextSelection.classList.remove('d-none');
        stopTextSelection.classList.add('d-block');
    }
}






