import {FormatTimes} from "../app-basic";

export function addHistoryItem(itemdata) {

    var $history = $('#history-list');
    var $historylistcard = $('#history-list-card')
    var $historyitemtpl = $('#history-item-tpl');

    // Check if elements exist
    if ($history.length === 0 || $historylistcard.length === 0 || $historyitemtpl.length === 0) {

        return; // Exit the function if elements do not exist
    }

    var data = {
        'created_on': FormatTimes.addDateTimeNow(),
        'instruction_type': itemdata.instruction_type,
        'user_input_preview': itemdata.user_input_preview,
        'result_preview': itemdata.result.substring(0, 500),
        'url': '/history/' + itemdata.history_code,
        'word_count': itemdata.word_count
    }


    var cardOutput = $historyitemtpl.render({'data': data});

    //add the template
    $historylistcard[0].className = 'card mt-4 d-block';
    $history.prepend(cardOutput);

}