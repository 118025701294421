// Define color styles for different log levels and categories
const COLORS = {
    DEBUG: 'color: green;',
    LOG: 'color: green;',  // Explicitly color LOG the same as DEBUG
    INFO: 'color: blue;',
    WARNING: 'color: orange;',
    ERROR: 'color: red;',
    CRITICAL: 'color: white; background-color: red; padding: 2px;',
};

const CATEGORY_COLORS = {
    GREEN: 'color: green;',
    BLUE: 'color: blue;',
    YELLOW: 'color: yellow;',
    RED: 'color: red;',
    PINK: 'color: pink;',
    PURPLE: 'color: purple;',
    CYAN: 'color: cyan;',
    GRAY: 'color: gray;',
    NAVY: 'color: navy;',
    ORANGE: 'color: orange;',
};

// Define numeric log levels for comparison
const LOG_LEVELS = {
    DEBUG: 0, LOG: 0,  // Treat LOG as the same level as DEBUG
    INFO: 1, WARNING: 2, ERROR: 3, CRITICAL: 4
};

// Use LOG_LEVEL from the environment, with 'ERROR' as a default fallback
const DEFAULT_LOG_LEVEL = typeof LOG_LEVEL_JAVASCRIPT !== 'undefined' ? LOG_LEVEL_JAVASCRIPT : 'ERROR';

class CustomLogger {
    constructor(category = null, color = null, logLevel = DEFAULT_LOG_LEVEL) {
        this.category = category;
        this.categoryStyle = color ? CATEGORY_COLORS[color.toUpperCase()] : '';
        this.logLevel = LOG_LEVELS[logLevel.toUpperCase()] !== undefined ? LOG_LEVELS[logLevel.toUpperCase()] : LOG_LEVELS.INFO;

        // Initialize methods based on the log level
        this._updateLogMethods();
    }

    setLevel(level) {
        if (LOG_LEVELS[level.toUpperCase()] !== undefined) {
            this.logLevel = LOG_LEVELS[level.toUpperCase()];
            this._updateLogMethods();
        } else {
            console.warn(`Invalid log level: ${level}`);
        }
    }

    _updateLogMethods() {
        // Define a no-op function for disabled methods
        const noop = () => {
        };

        this.log = this.logLevel <= LOG_LEVELS.LOG ? Function.prototype.bind.call(console.log, console, ...this._formatMessage('LOG')) : noop;
        this.debug = this.logLevel <= LOG_LEVELS.DEBUG ? Function.prototype.bind.call(console.log, console, ...this._formatMessage('DEBUG')) : noop;
        this.info = this.logLevel <= LOG_LEVELS.INFO ? Function.prototype.bind.call(console.info, console, ...this._formatMessage('INFO')) : noop;
        this.warn = this.logLevel <= LOG_LEVELS.WARNING ? Function.prototype.bind.call(console.warn, console, ...this._formatMessage('WARNING')) : noop;
        this.error = this.logLevel <= LOG_LEVELS.ERROR ? Function.prototype.bind.call(console.error, console, ...this._formatMessage('ERROR')) : noop;
        this.critical = this.logLevel <= LOG_LEVELS.CRITICAL ? Function.prototype.bind.call(console.error, console, ...this._formatMessage('CRITICAL')) : noop;
    }

    _formatMessage(level) {
        const categoryStyle = this.category ? this.categoryStyle : '';
        const levelColor = COLORS[level] || 'color: inherit;';
        const categoryPrefix = this.category ? `[${this.category}] ` : '';

        return [
            `%c${categoryPrefix}%c${level.padEnd(6, ' ')} %c`,
            categoryStyle,
            levelColor,
            ''
        ];
    }
}

// Default logger instance with log level set by environment
const defaultLogger = new CustomLogger();

// Function to create a custom logger with a specified log level
function customLogger(category = null, color = null, logLevel = DEFAULT_LOG_LEVEL) {
    return new CustomLogger(category, color, logLevel);
}

// Export the default logger and custom logger function
export default defaultLogger;
export {customLogger};
