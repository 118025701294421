import {processAIResult} from "./copywriting-result";

export function videoToTextLoad() {
    var mainContent = document.querySelector('.main-content');
    mainContent.classList.remove('fixed-plugin-pinned');
    var fixedPlugin = document.querySelector('.fixed-plugin');
    fixedPlugin.classList.remove('show');
}

export function addVideoToTextHistory(chat_history, version = 1) {
    if (chat_history != null) {


        var result = chat_history[1].content[0].text

        $('#upload-widget').fadeOut(2000, function () {
            var $videoResult = $("#videoresult");
            $videoResult[0].src = chat_history[0].content[0].file.url
            // $('#upload-widget').fadeOut(4000);
            var mainContent = document.querySelector('.main-content');
            mainContent.classList.add('fixed-plugin-pinned');
            var fixedPlugin = document.querySelector('.fixed-plugin');
            fixedPlugin.classList.add('show');
            // This code will run after the fadeOut operation is done.
            // You can now display your #aicopy.s
            var $divupload = $('#div-upload')
            $divupload[0].className = "d-none";
            var $aicopy = $('#aicopy');
            $aicopy[0].className = 'd-block';
            var response =
                {
                    "data": {
                        "title": "",
                        "header": "H1",

                        "position": "",
                        "result": result
                    }
                }

            processAIResult(response, false, false, false, true)

        });


    }
}