export var copyButtonLoad = function () {
    function copyText(buttonElement) {
        try {
            var chatCard = buttonElement.closest('.chat');
            var textElement = chatCard.querySelector('.name span[id]');
            if (!textElement) {
                textElement = document.getElementById('pasttext');
            }
    
            var htmlText = textElement.innerHTML;
            var plainText = textElement.textContent || textElement.innerText;
    
            // Create Blobs for HTML and plain text
            const htmlBlob = new Blob([htmlText], {type: 'text/html'});
            const textBlob = new Blob([plainText], {type: 'text/plain'});
    
            // Write to the clipboard using the Clipboard API
            navigator.clipboard.write([
                new ClipboardItem({
                    'text/html': htmlBlob,
                    'text/plain': textBlob
                }),
            ]);
    
            // Change the icon to a checkmark
            changeIconToCheck(buttonElement);
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    }    

    function changeIconToCheck(buttonElement) {
        buttonElement.classList.remove('fa-copy');
        buttonElement.classList.add('fa-check');
        buttonElement.title = 'Copied';
        setTimeout(function() {
            buttonElement.classList.remove('fa-check');
            buttonElement.classList.add('fa-copy');
            buttonElement.title = 'Copy';
        }, 2000);
    }

    // Adding event listeners dynamically to ensure multiple clicks are supported
    document.addEventListener('click', function(event) {
        if (event.target.matches('.fas.fa-copy')) {
            var iconElement = event.target;
            copyText(iconElement);
        }
    });
}
