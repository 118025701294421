import {outputbox} from "./copywriting";
import {addHistoryItem} from "./history";
import DOMPurify from 'dompurify';

var messagesQueue = [];
var typespeed = 1
var chattypespeed = 1
var t = 0
var typerange = 0
var typetext = ''
var typetextwords = 0
var typeselectionindex = 0
var typeselectionlength = 0
var selectafter = true
export var systemScroll = false;
var userScrolling = false;
var scrollCounter = 0
var userScrollingFinished = false
var scrollTimeout;
let previousScrollTop = 0;

export function reset_typerange() {
    typerange = 0
}

export function reset_systemScroll() {
    systemScroll = true
    previousScrollTop = 0
    // console.log('reset system scroll')
}

export function user_scrolling($chatContainer) {
    // the auto scroll goes down so if we detect up scrolling we stop the autoscroll
    const currentScrollTop = $chatContainer.scrollTop();

    // Determine the scroll direction
    const direction = currentScrollTop > previousScrollTop ? 'down' : 'up';
    // console.log('scroll direction ' + direction)
    if (direction === 'up') {
        systemScroll = false
        // console.log('stop system scroll by user scrolling')
    }
    // Update the previous scroll position
    previousScrollTop = currentScrollTop;

}

export function processAIResult(response, showtitle, select, history = true, multi_message = false) {

    //title range
    var range = 0
    var title = response.data.title
    var output_title_header = response.data.header
    var output_position = response.data.position
    // check if the output box has a selection, if so add an extra line space
    if (outputbox.getSelection() != null) {
        if (output_position == 'above') {
            range = outputbox.getSelection().index;
            //extra line space
            outputbox.insertText(range, '\n\n', {'header': false});
            range += 2;
        } else if (output_position == 'below') {
            range = (outputbox.getSelection().index + outputbox.getSelection().length);
            //extra line space
            outputbox.insertText(range, '\n\n', {'header': false});
            range += 2;
        } else if (output_position == 'replace') {
            range = (outputbox.getSelection().index);
            outputbox.deleteText(outputbox.getSelection().index, outputbox.getSelection().length)
            //extra line spaces
            outputbox.insertText(range, '\n\n', {'header': false});
            range += 2;
        }

    } else {

        range = outputbox.getLength();
    }

    if (output_position == 'top') {
        if (range > 1) {
            //extra line space
            outputbox.insertText(range, '\n\n', {'header': false});
        }
        range = 0
    }

    if (showtitle == true) {
        if (title != '\n') {
            outputbox.insertText(range, title, {
                'header': output_title_header
            })
            outputbox.insertText(range + title.length, '\n', {'header': false});
        }
    }

    var range2 = 0
    var value = response.data.result

    if (outputbox.selection.lastRange != null) {
        if (output_position == 'above') {
            range2 = outputbox.selection.lastRange.index - 1;
        } else if (output_position == 'below') {
            range2 = (outputbox.selection.lastRange.index - 1 + outputbox.selection.lastRange.length);
        } else if (output_position == 'replace') {
            range2 = outputbox.selection.lastRange.index;
            outputbox.deleteText(outputbox.selection.lastRange.index - 1, outputbox.selection.lastRange.length)
        }


    } else {
        range2 = outputbox.getLength();
    }

    if (output_position == 'top') {
        if (showtitle == true) {
            range2 = title.length
        } else {
            range2 = 0
        }
    }

    if (multi_message) {
        typetext = response.data.result
        toConvert += typetext
        toConvertQueue = []
        toConvertQueue.push(toConvert)

        // typetextwords = string_to_words_array(typetext)
        messagesQueue.push({text: typetext, index: 0});

        // Call chatTypeWriter if it's not already processing any message
        if (toConvertQueue.length === 1) {
            quillTypeWriterFormatOnFly();
        }
    } else {
        //let result_length = (value.length + title.length)
        t = 0
        typerange = range2
        typetext = value
        typetextwords = typetext.split(' ')

        typeselectionindex = range2

        // typeselectionlength = result_length
        typeselectionlength = value.length
        selectafter = select
        // typeWriter()

        if (typetext.length < 500) {
            typeWriter()
        } else {
            typeWriterWords()
        }
    }
    if (history) {
        addHistoryItem(response.data)
    }

    // outputbox.setSelection(range, result_length)
}

export function processAIResultStream(response, show_title, select) {

    //title range
    var range = 0
    var title = response.data.title
    var output_title_header = response.data.header
    var output_position = response.data.position
    // check if the output box has a selection, if so add an extra line space
    if (outputbox.getSelection() != null) {
        if (output_position == 'above') {
            range = outputbox.getSelection().index;
            //extra line space
            outputbox.insertText(range, '\n\n', {'header': false});
            range += 2;
        } else if (output_position == 'below') {
            range = (outputbox.getSelection().index + outputbox.getSelection().length);
            //extra line space
            outputbox.insertText(range, '\n\n', {'header': false});
            range += 2;
        } else if (output_position == 'replace') {
            range = (outputbox.getSelection().index);
            outputbox.deleteText(outputbox.getSelection().index, outputbox.getSelection().length)
            //extra line spaces
            outputbox.insertText(range, '\n\n', {'header': false});
            range += 2;
        }

    } else {

        range = outputbox.getLength();
    }

    if (output_position == 'top') {
        if (range > 1) {
            //extra line space
            outputbox.insertText(range, '\n', {'header': false});
        }
        range = 0
    }

    if (show_title) {
        if (title != '\n') {
            outputbox.insertText(range, title, {
                'header': output_title_header
            })
            outputbox.insertText(range + title.length, '\n', {'header': false});
        }
    }

    var range2 = 0
    var value = response.data.result

    if (outputbox.selection.lastRange != null) {
        if (output_position == 'above') {
            range2 = outputbox.selection.lastRange.index;
        } else if (output_position == 'below') {
            range2 = (outputbox.selection.lastRange.index + outputbox.selection.lastRange.length);
        } else if (output_position == 'replace') {
            range2 = outputbox.selection.lastRange.index;
            outputbox.deleteText(outputbox.selection.lastRange.index, outputbox.selection.lastRange.length)
        }


    } else {
        range2 = outputbox.getLength();
    }

    if (output_position == 'top') {
        if (show_title === true && title !== '\n') {
            range2 = title.length
        } else {
            range2 = 0
        }
        outputbox.scrollSelectionIntoView();
    }

    typerange = range2
    // console.log('typerange processs ai result stream: ' + typerange)
    outputbox.insertText(range2, '\n', {'header': false});

}


export var processAIStreamMessage = function (response, chat = true, $assistant = null, $chatContainer = null) {
    // Add the text to the queue of messages to print
    typetext = response.data

    toConvert += typetext
    toConvertQueue = []
    toConvertQueue.push(toConvert)


    messagesQueue.push({text: typetext, index: 0});

    // typetextwords = string_to_words_array(typetext)
    // messagesQueue.push({textWords: typetextwords, index: 0});

    // Call chatTypeWriter if it's not already processing any message
    if (toConvertQueue.length === 1) {
        if (chat) {
            chatTypeWriter($assistant[0], $chatContainer)
        } else {
            quillTypeWriterFormatOnFly();
        }
    }
}


function string_to_words_array(string) {
    //  keeps spacing of original string rather than just using .split(' ')
    const words = string.split(' ');
    const result = [];
    for (let i = 0; i < words.length; i++) {
        const end_char = i !== words.length - 1 ? ' ' : '';
        result.push(words[i] + end_char);
    }

    return result;
}

let toConvert = ''
let toConvertQueue = []
let alreadyProcessed = 0
let isProcessing = false;

export function reset_toConvert() {
    // console.log('reset toConvert')
    toConvert = ''
    alreadyProcessed = 0
}

export function chatTypeWriter($textElement, $container = null) {
    if (toConvertQueue.length > 0) {
        const latestConvert = toConvertQueue[toConvertQueue.length - 1]; // Get the most recent item
        toConvertQueue = [];  // Clear the queue

        //only need to do if system scroll is active.
        // console.log(systemScroll)
        if (systemScroll) {
            // Auto-scroll
            if ($container) {
                // Automatically scroll to the top of the last message
                const lastMessage = $container.children().last()[0];
                if (lastMessage) {

                    // Calculate the top position of the last message relative to the container
                    const lastMessageRect = lastMessage.getBoundingClientRect();
                    const containerRect = $container[0].getBoundingClientRect();

                    // Calculate the scrollTop value needed to make the top of lastMessage visible
                    const scrollTop = $container.scrollTop() + (lastMessageRect.top - containerRect.top);

                    // Define an epsilon value for floating-point comparison to allow for slight differences
                    const epsilon = 1;


                    // Only scroll if the last message is not already at the top of the container within the tolerance
                    if (Math.abs(lastMessageRect.top - containerRect.top) > epsilon) {
                        $container.scrollTop(scrollTop);

                    } else {
                        // if the last message is already at the top of the container stop auto scrolling
                        systemScroll = false
                        // console.log('stop system scroll')
                    }
                }

            }
        }

        // Markdown parsing and sanitization
        const convertedMarkdown = marked.parse(latestConvert);
        const safeHtml = DOMPurify.sanitize(convertedMarkdown);

        $textElement.innerHTML = safeHtml

        // Process the next message in the queue, if there is any
        if (toConvertQueue.length > 0) {
            chatTypeWriter($textElement, $container);
        }
    }
}

// function quillTypeWriter() {
//
//     if (messagesQueue.length > 0) {
//         const {textWords, index} = messagesQueue[0];
//
//         if (index < textWords.length) {
//             let word = textWords[index];
//             outputbox.insertText(typerange, word, {'header': false})
//             typerange += word.length
//             messagesQueue[0].index++;
//             setTimeout(quillTypeWriter.bind(null), chattypespeed);
//         } else {
//             //auto scroll if user isn't scrolling
//
//             // console.log(messagesQueue.length)
//             messagesQueue.shift();
//
//             quillTypeWriter(); // Process the next message in the queue, if there is any
//         }
//     }
// }

export function quillTypeWriter() {
    if (messagesQueue.length > 0) {
        const currentMessage = messagesQueue[0];
        const {text, index} = currentMessage;


        outputbox.insertText(typerange, text, {'header': false}, 'silent');
        typerange += text.length;
        messagesQueue[0].index++;


        messagesQueue.shift();


        quillTypeWriter(); // Process the next message in the queue, if there is any
    }

}

export function quillTypeWriterFormatOnFly() {
    //check if there is any text in the queue
    if (toConvertQueue.length > 0) {
        // console.log('typerange: ' + typerange)
        const latestConvert = toConvertQueue[toConvertQueue.length - 1]; // Get the most recent item
        toConvertQueue = [];  // Clear the queue

        // Markdown parsing and sanitization
        const convertedMarkdown = marked.parse(latestConvert);
        const safeHtml = DOMPurify.sanitize(convertedMarkdown);


        // only delete if we have previously processed some text (so not the first run)
        if (alreadyProcessed !== 0) {
            //delete the previously inserted text
            outputbox.deleteText(typerange, alreadyProcessed, 'silent');
            // console.log('delete: ' + typerange + ' - ' + alreadyProcessed)
        }

        // Insert the new HTML content

        outputbox.clipboard.dangerouslyPasteHTML(typerange, safeHtml, 'silent');
        // Get the final cursor position to know where to delete up to in the next run
        var postion = outputbox.getSelection()
        // outputbox.scrollSelectionIntoView();
        // console.log('postion: ' + postion.index)

        // We need to take off the typerange because we might not be starting from the start of the document
        alreadyProcessed = postion.index - typerange;
        // console.log('alreadyProcessed: ' + alreadyProcessed + '( ' + postion.index + ' - ' + typerange + ' )')

    }

    // Process the next message in the queue, if there is any
    if (toConvertQueue.length > 0) {

        quillTypeWriterFormatOnFly();
    }
}

function concatenateMessages(counter) {
    let i = 1;
    while (i < messagesQueue.length) {
        const nextMessage = messagesQueue[i];
        messagesQueue[0].textWords.push(...nextMessage.textWords);
        messagesQueue.splice(i, 1); // Remove the concatenated message from the queue

        counter++; // Increment the counter for concatenated messages
    }

    // Return the updated counter
    return counter;
}

export function typeWriter() {
    if (t < typetext.length) {
        outputbox.insertText(typerange + t, typetext.charAt(t), {'header': false})
        outputbox.setSelection(0, 0);
        outputbox.root.blur()

        t++;
        setTimeout(typeWriter, typespeed);
    }
    if (t == typetext.length) {
        if (selectafter) {
            outputbox.setSelection(typeselectionindex, typeselectionlength, 'user');
        } else {
            outputbox.root.blur()
        }
    }
}

export function typeWriterWords() {

    if (t < typetextwords.length) {
        let word = typetextwords[t];
        outputbox.insertText(typerange, word + ' ', {'header': false})
        outputbox.setSelection(0, 0);
        outputbox.root.blur()
        typerange += (word.length + 1);
        t++;
        setTimeout(typeWriterWords, typespeed);
    }
    if (t == typetextwords.length) {
        if (selectafter) {
            outputbox.setSelection(typeselectionindex, typeselectionlength, 'user');
        } else {
            outputbox.root.blur()
        }
    }
}
