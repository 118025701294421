import {pusher} from "./extensions";
import {pusherJobMessage} from "./copywriting-post";
import {pusherJobMessageAICaptions} from "./video_captions";

export var uploader;
if (typeof Dropzone !== 'undefined') {
    Dropzone.autoDiscover = false;
}

export function uploadImageToS3(file, callback) {
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (req.status === 200) {
                const signedPost = JSON.parse(req.responseText);
                if (!signedPost.job_limit) {
                    const formData = new FormData();
                    Object.keys(signedPost.fields).forEach(key => {
                        formData.append(key, signedPost.fields[key]);
                    });
                    formData.append('file', file);

                    const uploadReq = new XMLHttpRequest();
                    uploadReq.open('POST', signedPost.url, true);
                    uploadReq.onload = () => {
                        if (uploadReq.status === 204) {
                            callback(null, signedPost);
                        } else {
                            callback('Upload failed');
                        }
                    };
                    uploadReq.onerror = () => {
                        callback('Upload failed');
                    };
                    uploadReq.send(formData);
                } else {
                    callback(signedPost.counterText);
                }
            } else {
                callback('Error obtaining signed URL');
            }
        }
    };
    req.open('POST', '../api/v1/files/sign_s3/');
    req.setRequestHeader('X-CSRF-TOKEN', $.cookie('csrf_access_token'));
    req.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    req.send(JSON.stringify({ job_type: 'image_upload' }));
}

export default function fileUploadToS3(job_type = "video_to_text", message = "Drop or click here to upload your data") {
    var $progress = $('.progress');
    $progress[0].style.display = 'block';

    var previewNode = document.querySelector("#tpl");
    //previewNode[0].style.display = null;
    if (previewNode) {
        var previewTemplate = previewNode.outerHTML;
        previewNode.id = "";
        previewNode.parentNode.removeChild(previewNode);

        if ($('#upload-widget').length) {
            uploader = new Dropzone("#upload-widget", {
                    dictDefaultMessage: message,
                    url: "https://endpoint.s3.amazonaws.com/",  // will be overwritten dynamically
                    method: "POST",
                    timeout: 3600000,
                    parallelUploads: 1,
                    maxFilesize: 125,  // MB
                    autoQueue: true,
                    autoProcessQueue: true,
                    addRemoveLinks: false,
                    createImageThumbnails: false,
                    previewTemplate: previewTemplate,
                    accept: function (file, done) {
                        const req = new XMLHttpRequest();
                        req.onreadystatechange = () => {
                            if (req.readyState === XMLHttpRequest.DONE) {
                                if (req.status === 200) {
                                    const signedPost = JSON.parse(req.responseText);
                                    if (signedPost.job_limit === false) {
                                        this.options.url = signedPost.url;
                                        file.signedPost = signedPost;
                                        done();
                                    } else {
                                        done(signedPost.counterText);
                                    }
                                } else {
                                    done("Error uploading, please try again");
                                }
                            }
                        };
                        req.open("POST", '../api/v1/files/sign_s3/');
                        req.setRequestHeader('X-CSRF-TOKEN', $.cookie('csrf_access_token'));
                        req.setRequestHeader("Content-type", "application/json; charset=UTF-8");
                        req.send(JSON.stringify({
                            job_type: job_type
                        }));
                    },
                }
            )
            ;
            var $uploadbutton = $('.dz-button');
            $uploadbutton[0].className += ' btn btn-outline-primary mt-n3 w-100';

            uploader.on('sending', (file, xhr, formData) => {
                var $dropicon = $('#dropicon');
                $dropicon.removeClass('fa-3x');

                const fields = file.signedPost.fields;
                Object.keys(fields).forEach(k => formData.append(k, fields[k]));

                var $uploadresult = $('#update-message');
                $uploadresult.text("Uploading file");
                var $uploadbutton = $('.dz-default');
                $uploadbutton[0].style.display = 'none';
                var privateJobChannel = pusher.subscribe('private-jobid-' + file.signedPost.job_id);

                if (job_type === 'video_captions') {
                    document.getElementById('job_id').value = file.signedPost.job_id;
                    pusherJobMessageAICaptions(privateJobChannel, 'message')
                } else {
                    pusherJobMessage(privateJobChannel, 'message')
                }
            });

            uploader.on("success", (file) => {
                uploader.options.autoProcessQueue = true;  // https://github.com/enyo/dropzone/issues/462

                var $uploadresult = $('#update-message');
                $uploadresult.text("Upload complete");
                var $videoResult = $("#videoresult");
                $videoResult[0].src = file.signedPost.new_url;

                var mainContent = document.querySelector('.main-content');
                mainContent.classList.add('fixed-plugin-pinned');
                var fixedPlugin = document.querySelector('.fixed-plugin');
                fixedPlugin.classList.add('show');
            });

            uploader.on("queuecomplete", () => {
                uploader.options.autoProcessQueue = false;
            });
        }
    }
}
