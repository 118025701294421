var video_captions_outputbox


export var loadVideoCaptions = function () {
    // const Inline = Quill.import('blots/inline');
    // const BlockEmbed = Quill.import('blots/block/embed');

    class WordCardBlot extends Quill.import('blots/block') {
        static create(metadata) {
            let node = super.create();
            node.setAttribute('data-start-time', metadata.start_time);
            node.setAttribute('data-end-time', metadata.end_time);
            node.setAttribute('data-cut', metadata.cut);
            node.innerText = metadata.word || '\u00A0'; // Initializing with non-breaking space
            node.setAttribute('contenteditable', false);
            node.classList.add('word-card');
            if (metadata.cut === 'true') {
                node.classList.add('cut-word-card'); // Add class for cards with cut = true
            }
            node.addEventListener('click', () => {
                // node.classList.toggle('selected');
                // openPopupEditor(event, node);
                toggleWordCardSelection(event, node);

            }); // Attach click event
            return node;
        }

        static value(node) {
            return {
                word: node.innerText.trim(),
                start_time: node.getAttribute('data-start-time'),
                end_time: node.getAttribute('data-end-time'),
                cut: node.getAttribute('data-cut')
            };
        }
    }

    WordCardBlot.blotName = 'wordCard';
    WordCardBlot.tagName = 'div';
    WordCardBlot.className = 'word-card';
    Quill.register(WordCardBlot);

// Popup Handling
    const popupEditor = document.getElementById('popup-editor');
    const popupInput = document.getElementById('popup-input');
    const startTimeInput = document.getElementById('start-time');
    const endTimeInput = document.getElementById('end-time');
    const cutCheckbox = document.getElementById('cut-checkbox');
    const mergeButton = document.getElementById('merge-button');


    let currentWordCard = null;

    // Function to highlight all selected word cards
    function highlightSelectedWords() {
        const selectedWordCards = document.querySelectorAll('.word-card.selected');
        selectedWordCards.forEach((wordCard) => {
            wordCard.classList.add('selected');
        });
    }

// Function to remove highlight from all selected word cards
    function removeHighlightFromSelectedWords() {
        const highlightedWordCards = document.querySelectorAll('.word-card.selected');
        highlightedWordCards.forEach((wordCard) => {
            wordCard.classList.remove('selected');
        });
    }

// Open the popup and highlight all selected words
    function openPopupEditor(event, wordCardNode) {
        // Highlight all selected words
        highlightSelectedWords();

        currentWordCard = wordCardNode;
        currentWordCard.classList.add('selected');
        popupInput.value = wordCardNode.innerText.trim();
        startTimeInput.value = wordCardNode.getAttribute('data-start-time');
        endTimeInput.value = wordCardNode.getAttribute('data-end-time');
        cutCheckbox.checked = wordCardNode.getAttribute('data-cut') === 'true';
        // Editor and Word Card positions
        const wordCardRect = wordCardNode.getBoundingClientRect();

        // Calculate the position within the editor
        // Calculate the position within the editor
        const top = wordCardRect.bottom// Positioning below the word card

        const left = wordCardRect.x - 28

        // Set the popup position
        popupEditor.style.top = `${top}px`;
        popupEditor.style.left = `${left}px`;

        popupEditor.style.display = 'block';
        popupInput.focus();
        popupInput.select();
    }

    function closePopupEditor() {
        popupEditor.style.display = 'none';
        if (currentWordCard) {
            removeHighlightFromSelectedWords();
        }
        currentWordCard = null;
    }

    mergeButton.addEventListener('click', mergeSelectedWords);
    mergeButton.style.display = 'none';

    // Function to merge selected word cards
    function mergeSelectedWords() {
        const selectedWordCards = Array.from(document.querySelectorAll('.word-card.selected'));
        if (selectedWordCards.length !== 2) {
            alert('Please select exactly two words to merge.');
            return;
        }

        const [firstWordCard, secondWordCard] = selectedWordCards;

        // Determine the correct order of selected cards in Quill Editor
        const firstQuillIndex = getBlotIndex(video_captions_outputbox, firstWordCard);
        const secondQuillIndex = getBlotIndex(video_captions_outputbox, secondWordCard);
        const startIndex = Math.min(firstQuillIndex, secondQuillIndex);
        const endIndex = Math.max(firstQuillIndex, secondQuillIndex);

        const startTime = firstWordCard.getAttribute('data-start-time');
        const endTime = secondWordCard.getAttribute('data-end-time');
        const cutValue = 'false';

        const combinedWord = `${firstWordCard.innerText.trim()} ${secondWordCard.innerText.trim()}`;

        // Calculate the length to delete more accurately
        const lengthToDelete = endIndex - startIndex + secondWordCard.innerText.length + 1;

        // Remove old word cards from the editor
        video_captions_outputbox.deleteText(startIndex, lengthToDelete);

        // Insert the merged word card at the position of the first word card
        video_captions_outputbox.insertEmbed(startIndex, 'wordCard', {
            word: combinedWord,
            start_time: startTime,
            end_time: endTime,
            cut: cutValue
        });
        closePopupEditor();

    }


    // Helper function to get the Quill position of a word card element
    function getBlotIndex(quillEditor, element) {
        const blot = Quill.find(element);
        return quillEditor.getIndex(blot);
    }


    // Toggle the selection state of a word card
    function toggleWordCardSelection(event, wordCardNode) {
        const selectedWordCards = document.querySelectorAll('.word-card.selected');

        // Check if more than two cards are already selected
        if (selectedWordCards.length >= 2) {
            // Clear selection from all previously selected word cards
            selectedWordCards.forEach(card => {
                card.classList.remove('selected');
            });
        }

        // Toggle the selection state of the current word card
        wordCardNode.classList.toggle('selected');

        // Open the popup editor only if the card is selected
        if (wordCardNode.classList.contains('selected')) {
            openPopupEditor(event, wordCardNode);
        } else {
            closePopupEditor();
        }

        toggleMergeButtonVisibility(); // Check selected count and toggle merge button
    }

    // Function to check and toggle the visibility of the merge button
    function toggleMergeButtonVisibility() {
        const selectedWordCards = document.querySelectorAll('.word-card.selected').length;
        if (selectedWordCards === 2) {
            mergeButton.style.display = 'block';  // Show the merge button
        } else {
            mergeButton.style.display = 'none';   // Hide the merge button
        }
    }


    // Event Listener for Save Button
    document.getElementById('popup-save').addEventListener('click', function () {
        if (currentWordCard) {
            currentWordCard.innerText = popupInput.value.trim() || '\u00A0';
            currentWordCard.setAttribute('data-cut', cutCheckbox.checked);
            currentWordCard.setAttribute('data-start-time', startTimeInput.value);
            currentWordCard.setAttribute('data-end-time', endTimeInput.value);
            currentWordCard.classList.remove('selected');
            // Update the transcription data
            var transcription_data = generateTranscriptionJSONFromQuill()


            closePopupEditor();
            process_transcription(transcription_data);
        }
    });

    document.getElementById('popup-cancel').addEventListener('click', closePopupEditor);

    window.addEventListener('click', function (event) {
        if (!popupEditor.contains(event.target) && event.target !== currentWordCard) {
            closePopupEditor();
        }
    });

    video_captions_outputbox = new Quill('#editor', {
        contenteditable: 'false'
    });

    // Attaching click event listeners to existing word cards in the editor
    document.querySelectorAll('.word-card').forEach((wordCard) => {
        wordCard.addEventListener('click', (event) => {
            wordCard.classList.toggle('selected');
            if (wordCard.classList.contains('selected')) {
                openPopupEditor(event, wordCard);
            }
            toggleMergeButtonVisibility();  // Check selected count and toggle merge button
        });
    });

    // Disable contenteditable on the Quill editor container
    document.querySelector('.ql-editor').setAttribute('contenteditable', false);


    // Highlight words in sync with video
    const video = document.getElementById('videoresult');

    video.addEventListener('timeupdate', function () {
        const currentTime = video.currentTime;
        highlightWords(currentTime);
    });

    function highlightWords(currentTime) {
        const words = document.querySelectorAll('.word-card');
        words.forEach(word => {
            const startTime = parseFloat(word.getAttribute('data-start-time'));
            const endTime = parseFloat(word.getAttribute('data-end-time'));
            if (currentTime >= startTime && currentTime <= endTime) {
                word.classList.add('highlight');
            } else {
                word.classList.remove('highlight');
            }
        });
    }

    // Add the event listener to the button
    document.getElementById('caption_button').addEventListener('click', function () {
        // Generate the transcription JSON from Quill editor content
        const transcriptionJSON = generateTranscriptionJSONFromQuill();

        // Send the generated JSON to the API
        sendTranscriptionToAPI(transcriptionJSON);

    });


}

export var process_transcription = function (transcription) {

    const sentences = wordBasedSentences(transcription.words);

    // video_captions_outputbox.setContents([{ insert: '\n' }]);


    // Optional: Ensure the editor is blank
    if (video_captions_outputbox.getLength() > 1) {
        video_captions_outputbox.deleteText(0, video_captions_outputbox.getLength());
    }

    // Optional: Add a blank line (if needed, depending on your editor setup)
    video_captions_outputbox.setText('');

    // Remove any remaining word cards or custom elements
    const wordCards = document.querySelectorAll('.word-card');
    wordCards.forEach(card => card.remove());

    sentences.forEach(sentence => {
        sentence.forEach(word => {
            video_captions_outputbox.insertEmbed(video_captions_outputbox.getLength(), 'wordCard', {
                word: word.word,
                start_time: word.start_time,
                end_time: word.end_time,
                cut: word.cut
            });

        });
        video_captions_outputbox.insertText(video_captions_outputbox.getLength(), '\n'); // Add new line after each sentence
    });

}

function wordBasedSentences(words, maxSentenceLength = 100) {
    const sentences = [];
    let currentSentence = [];

    for (let i = 0; i < words.length; i++) {
        const word = words[i];

        // Check if the current word starts with an uppercase letter and begin a new sentence if necessary
        if (word.word.length > 0 && word.word[0] === word.word[0].toUpperCase()) {
            if (currentSentence.length > 0 && words[i - 1].word[0] !== words[i - 1].word[0].toUpperCase()) {
                // Found the beginning of a new sentence
                sentences.push([...currentSentence]); // Push the current sentence as a new sentence
                currentSentence = [];
            }
        }

        currentSentence.push(word);

        // Check if the current sentence has reached the max sentence length
        if (currentSentence.length >= maxSentenceLength) {
            // Found the maximum sentence length
            sentences.push([...currentSentence]); // Push the current sentence as a new sentence
            currentSentence = [];
        }
    }

    // Add the last sentence if there are any remaining words
    if (currentSentence.length > 0) {
        sentences.push([...currentSentence]);
    }

    return sentences;
}

// Function to generate transcription JSON
function generateTranscriptionJSONFromQuill() {


    const transcription = {
        words: []
    };

    // Select all word-card elements from the editor
    const words = document.querySelectorAll('.word-card');

    words.forEach((word, idx) => {
        transcription.words.push({
            idx: idx,
            word: word.innerText.trim(),
            start_time: parseFloat(word.getAttribute('data-start-time')),
            end_time: parseFloat(word.getAttribute('data-end-time')),
            cut: word.getAttribute('data-cut')
        });
        if (word.getAttribute('data-cut') === 'true') {
            word.classList.add('cut-word-card');
        } else {
            word.classList.remove('cut-word-card');
        }
    });

    return transcription;
}

// Function to send transcription JSON to the API
function sendTranscriptionToAPI(transcription) {

    var apiUrl = '/api/v1/copywriting/update-captions'

    // Retrieve CSRF token from the hidden input
    var csrfToken = document.getElementById('csrf_token').value;

    var job_id = document.getElementById('job_id').value;
    transcription.job_id = job_id


    var submitButton = document.getElementById('caption_button');
    submitButton.classList.add('disabled');

    var caption_spinner = document.getElementById('caption_spinner');
    caption_spinner.classList.remove('d-none');

    var pusherMessageText = document.getElementById('pusher_message_text');
    pusherMessageText.classList.remove('d-none');
    pusherMessageText.textContent = ''

    fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken // Include the CSRF token in headers
        },
        body: JSON.stringify(transcription)
    })
        .then(response => response.json())
        .then(data => {

        })
        .catch((error) => {
            console.error('Error:', error);
            spinner.classList.add("d-none");
            submitButton.classList.remove('disabled');

        });
}


export var pusherJobMessageAICaptions = function (channel, event) {
    channel.bind(event, function (data) {
        var $pusherMessageText = $('#pusher_message_text');

        if (data.message.messages[0].result != null) {

            var result = data.message.messages[0].result

            if (data.message.messages[0].result_name === 'new_video') {
                var $videoResult = $("#videoresult");
                $videoResult[0].src = result
                var $submitbutton = $('#caption_button');
                $submitbutton.removeClass('disabled');
                var $caption_spinner = $('#caption_spinner');
                $caption_spinner.addClass('d-none');
                $pusherMessageText.text('Captions Complete')
            } else {
                $pusherMessageText.text('');
                $pusherMessageText.removeClass('d-none')
                $('#upload-widget').fadeOut(2000, function () {
                    // This code will run after the fadeOut operation is done.
                    // You can now display your #aicopy.s
                    var $divupload = $('#div-upload')
                    $divupload[0].className = "d-none";
                    var $aicopy = $('#aicopy');
                    $aicopy[0].className = 'd-block';

                    var $caption_button = $('#caption_button');
                    $caption_button.removeClass('d-none');
                    process_transcription(result)

                });
            }


        } else {
            var message = data.message.messages[0].message
            $('[data-dz-name]').text(message);
            $('[data-dz-size]').text("");

            $pusherMessageText.text(message);

        }
    });
};