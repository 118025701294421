import {io} from "socket.io-client";
import assistantManager from './assistant'
import logger from "./logger";

export var voiceLoad = function () {
    // Initialize Socket.io connection


    const assistantId = assistantManager.getSelectedAssistantId();
    logger.log('assistantId: ' + assistantId)
    const socket = io({
        query: {
            assistant_id: assistantId
        }
    });
    let isRecording = false;
    let audioStream = null;
    let audioContext = null;
    let sourceNode = null;
    let processorNode = null;
    let playbackAudioContext = null;
    let pcmDataBuffer = [];
    let totalSamples = 0;
    let originalSampleRate = null;
    const finalSampleRate = 24000;
    let resamplingDone = false;
    let first_message = true

    document.getElementById('audioButton').addEventListener('click', toggleAudioStreaming);

// Log connection events
    socket.on('connect', () => {
        logger.log('Connected to the server via WebSocket.');
    });
// Handle connection errors before connection is established
    socket.on('connect_error', (error) => {
        logger.error('Connection failed:', error.message);
    });

    socket.on('disconnect', () => {
        logger.log('Disconnected from the server.');
    });

    assistantManager.addListener((newAssistantId) => {
        logger.log('Updated assistantId: ' + newAssistantId);
        socket.emit('update_assistant_message', { assistant_id: newAssistantId });
    });

    async function toggleAudioStreaming(token_limit = false) {
        // Use the event object to get the element that was clicked
        const audioButton = document.getElementById('audioButton')

        // Toggle the 'border' class
        audioButton.classList.toggle('border');

        const header_text = document.getElementById('voice-header')
        if (!isRecording) {
            // Start recording
            isRecording = true;
            await startAudioStreaming();
            logger.log('Started audio streaming.');

            header_text.innerHTML = "<b class='text-primary'>Say Hi<b> Wilfie is listening..."
        } else {
            // Stop recording
            isRecording = false;
            stopAudioStreaming();
            logger.log('Stopped audio streaming.');

            header_text.innerText = "Press the Wilfie button to start your conversation"
        }

        if (token_limit === true) {
            logger.log('token_limit disable button')
            audioButton.classList.add('disabled');
            header_text.innerHTML = "<b class='text-secondary'>Token Limit Reached.<b> Please upgrade your subscription."
        }


    }


    async function startAudioStreaming() {
        try {
            // Get microphone access
            audioStream = await navigator.mediaDevices.getUserMedia({audio: true});
            // Create audio context
            audioContext = new (window.AudioContext || window.webkitAudioContext)();
            originalSampleRate = audioContext.sampleRate;
            // logger.log(`Original sample rate: ${originalSampleRate} Hz`);

            // Create source node from the microphone
            sourceNode = audioContext.createMediaStreamSource(audioStream);
            // Create processor node
            processorNode = audioContext.createScriptProcessor(4096, 1, 1);

            // Handle audio processing
            processorNode.onaudioprocess = function (e) {
                if (!isRecording) return;
                // Get the input buffer
                let inputBuffer = e.inputBuffer;
                // Get data from the first channel
                let inputData = inputBuffer.getChannelData(0);

                // Resample if necessary
                let resampledData = inputData;
                if (originalSampleRate !== finalSampleRate) {
                    resampledData = resampleBuffer(inputData, originalSampleRate, finalSampleRate);
                    if (!resamplingDone) {
                        // logger.log(`Resampling audio from ${originalSampleRate} Hz to ${finalSampleRate} Hz.`);


                        resamplingDone = true;
                    }
                }

                // Convert float audio data to 16-bit PCM
                let pcmData = convertFloat32ToInt16(resampledData);

                // Append pcmData to pcmDataBuffer
                pcmDataBuffer.push(pcmData);
                totalSamples += pcmData.length;

                // Check if we have accumulated 1 second of audio
                if (totalSamples >= finalSampleRate) { // Since sample rate is 24000 Hz
                    // Concatenate all pcmData into a single Int16Array
                    let totalLength = pcmDataBuffer.reduce((sum, arr) => sum + arr.length, 0);
                    let allPcmData = new Int16Array(totalLength);
                    let offset = 0;
                    for (let arr of pcmDataBuffer) {
                        allPcmData.set(arr, offset);
                        offset += arr.length;
                    }

                    // Send PCM data to the server
                    socket.emit('audio_message', {audio: arrayBufferToBase64(allPcmData.buffer)});
                    // logger.log('Sent audio message to server.');

                    // Reset buffer and sample count
                    pcmDataBuffer = [];
                    totalSamples = 0;
                }
            };

            // Connect nodes
            sourceNode.connect(processorNode);
            processorNode.connect(audioContext.destination); // Needed in Chrome to start processing

            // Initialize playbackAudioContext if not already
            if (!playbackAudioContext) {
                playbackAudioContext = new (window.AudioContext || window.webkitAudioContext)();
            }

        } catch (err) {
            console.error('Error accessing microphone', err);
        }
    }

    function stopAudioStreaming() {
        if (processorNode) {
            processorNode.disconnect();
        }
        if (sourceNode) {
            sourceNode.disconnect();
        }
        if (audioContext) {
            audioContext.close();
        }
        if (audioStream) {
            audioStream.getTracks().forEach(track => track.stop());
        }

        // Send any remaining audio data
        if (pcmDataBuffer.length > 0) {
            // Concatenate all pcmData into a single Int16Array
            let totalLength = pcmDataBuffer.reduce((sum, arr) => sum + arr.length, 0);
            let allPcmData = new Int16Array(totalLength);
            let offset = 0;
            for (let arr of pcmDataBuffer) {
                allPcmData.set(arr, offset);
                offset += arr.length;
            }

            // Send PCM data to the server
            socket.emit('audio_message', {audio: arrayBufferToBase64(allPcmData.buffer)});
            // logger.log('Sent final audio message to server.');

            // Reset buffer and sample count
            pcmDataBuffer = [];
            totalSamples = 0;
        }

        // Reset variables
        pcmDataBuffer = [];
        totalSamples = 0;
        processorNode = null;
        sourceNode = null;
        audioContext = null;
        audioStream = null;
        resamplingDone = false;
    }

    function convertFloat32ToInt16(buffer) {
        let l = buffer.length;
        let buf = new Int16Array(l);
        while (l--) {
            let s = Math.max(-1, Math.min(1, buffer[l]));
            buf[l] = s < 0 ? s * 0x8000 : s * 0x7FFF;
        }
        return buf;
    }

    function arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    function resampleBuffer(buffer, fromSampleRate, toSampleRate) {
        let ratio = fromSampleRate / toSampleRate;
        let newLength = Math.round(buffer.length / ratio);
        let resampledBuffer = new Float32Array(newLength);
        let offsetResult = 0;
        let offsetBuffer = 0;
        while (offsetResult < resampledBuffer.length) {
            let nextOffsetBuffer = Math.round((offsetResult + 1) * ratio);
            // Use average value between offsets
            let accum = 0, count = 0;
            for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
                accum += buffer[i];
                count++;
            }
            resampledBuffer[offsetResult] = accum / count;
            // Move to next
            offsetResult++;
            offsetBuffer = nextOffsetBuffer;
        }
        return resampledBuffer;
    }

    function base64ToArrayBuffer(base64) {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    let audioPlaybackTime = 0;
    let audioQueue = [];
    let isPlaying = false;

    function playNextInQueue() {
        if (audioQueue.length > 0 && !isPlaying) {
            isPlaying = true;
            let nextAudioBuffer = audioQueue.shift();
            playAudioBuffer(nextAudioBuffer);
        }
    }

    function playAudioBuffer(arrayBuffer) {
        let pcmData = new Int16Array(arrayBuffer);

        // Convert Int16Array to Float32Array for playback
        let float32Data = new Float32Array(pcmData.length);
        for (let i = 0; i < pcmData.length; i++) {
            float32Data[i] = pcmData[i] / 0x8000;
        }

        // Create an AudioBuffer
        let audioBuffer = playbackAudioContext.createBuffer(1, float32Data.length, finalSampleRate);
        audioBuffer.getChannelData(0).set(float32Data);

        // Create a buffer source
        let bufferSource = playbackAudioContext.createBufferSource();
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(playbackAudioContext.destination);

        // Set the onended event to play the next in the queue
        bufferSource.onended = function () {
            isPlaying = false;
            playNextInQueue();
        };

        // Start playback
        bufferSource.start();
        // logger.log('Playing audio buffer.');
    }

// Handle incoming messages from the server
    socket.on('message', async (data) => {
            const messageType = data.type;
            // logger.log(`Received message type: ${messageType}`);
            if (messageType === 'input_audio_buffer.speech_started') {
                // Clear the audio queue
                audioQueue = [];
                logger.log('Audio queue cleared due to speech start.');
                isPlaying = false; // Stop any current playback if necessary
            } else if (messageType === 'response.audio.delta') {
                let base64AudioData = data.delta;
                let audioBuffer = base64ToArrayBuffer(base64AudioData);

                // Queue the audio message
                audioQueue.push(audioBuffer);
                // logger.log('Queued audio message.');

                // Try to play next in queue if nothing is currently playing
                playNextInQueue();
                if (first_message) {
                    first_message = false
                    const header_text = document.getElementById('voice-header')
                    header_text.innerText = "Press the button again to end your conversation"
                }

            } else if (messageType === 'response.done') {
                logger.log('Audio streaming completed.');
                audioPlaybackTime = 0;
            } else if (messageType === "token_limit_reached") {
                logger.log('Token limit reached')
                await toggleAudioStreaming(true);
            }
        }
    )
    ;
}